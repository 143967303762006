@mixin mediaQuery($breakpoint) {
  @if $breakpoint==lg {
    @media (max-width: 1240px) {@content}
  }
  @if $breakpoint==md {
    @media (max-width: 900px) {@content}
  }
  @if $breakpoint==sm {
    @media (max-width: 380px) {@content}
  }
}
/* clear할 클래스에  @include clear 해주면 됨 */
@mixin clear {
  &::after {
    content:"";
    display: block;
    clear: both;
  }
}
@mixin xCenter {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
@mixin yCenter {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
@mixin Center {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
}
@mixin flex {display: flex;}
@mixin flexBetween {display: flex;justify-content: space-between;}
@mixin ellipsis($lines) {
  @if $lines!= 1 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  } @else {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}