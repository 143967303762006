@charset "UTF-8";
/* clear할 클래스에  @include clear 해주면 됨 */
/* ======= GLOBAL ======= */
/* color */
/* font */
/* radius */
/* ======= GLOBAL 끝 ======= */
/* ======= COMMON ======= */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap");
@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
*::placeholder {
  color: #ccc;
}

body, html {
  min-width: 320px;
  max-width: 1000px;
  margin: 0 auto;
  font-size: 14px;
  font-family: "Noto Sans KR", sans-serif;
}

fieldset {
  padding: 0;
  border: 0;
}

input,
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
}

select {
  color: #000 !important;
}
select option {
  color: #000 !important;
}

dl, dt, dd {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

button {
  border: 0;
  background-color: transparent;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 17px;
}

h4 {
  font-size: 15px;
}

small {
  font-size: 13px;
}

/* 인풋 */
.chk_default {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  vertical-align: middle;
  background: url(@assets/img/img_check_not.png) no-repeat center/contain;
}
.chk_default:checked {
  background: url(@assets/img/img_check_green.png) no-repeat center/contain;
}

.chk_circle {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: url(@assets/img/img_check_line_not.png) no-repeat center/contain;
}
.chk_circle:checked {
  background: url(@assets/img/img_check_line.png) no-repeat center/contain;
}

.input_default {
  border: 1px solid #E1E3E7;
  border-radius: 10px;
}
.input_default:disabled {
  background-color: #F5F7F9;
}

.select_default {
  border: 1px solid #E1E3E7;
  border-radius: 10px;
  background: url(@assets/img/arrow_down_gray_thin.png) no-repeat calc(100% - 15px) center/12px #fff;
}
.select_default:disabled {
  background-color: #F5F7F9;
}
.select_default:focus {
  background: url(@assets/img/arrow_up_gray_thin.png) no-repeat calc(100% - 15px) center/12px;
}

.input_countdown {
  position: relative;
}

.input_warn {
  padding-top: 10px;
  color: #FA4163;
  font-size: 11px;
}
.input_warn img {
  width: 18px;
  height: 18px;
}

.input_value {
  padding: 0 !important;
  height: auto !important;
  border: 0;
  font-weight: bold;
}

.input_address_wrap {
  margin-bottom: 15px;
}
.input_address_wrap label {
  display: block;
  margin-bottom: 10px;
}
.input_address_wrap input {
  margin-bottom: 15px;
}
.input_address_wrap .input_postcode {
  position: relative;
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
}
.input_address_wrap .input_postcode button {
  width: 100%;
  height: 50px;
  max-width: 80px;
  border-radius: 10px;
  background-color: #757575;
  font-weight: bold;
  color: #fff;
}

/* 버튼 */
.btn_default {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #00BF8E;
  font-weight: bold;
  color: #fff;
}

.btn_default[data-bg=gray] {
  background-color: #ccc;
}

.btn_default_r5 {
  width: 100%;
  max-width: 96px;
  height: 30px;
  border-radius: 5px;
  background-color: #00BF8E;
  font-weight: bold;
  color: #fff;
}

.btn_default_r5[data-bg=gray] {
  background-color: #757575;
  color: rgba(255, 255, 255, 0.5);
}

.btn_default_r5[data-bg=blue] {
  background-color: #4A7EFE;
}

.btn_default_r5[data-bg=red] {
  background-color: #FA4163;
}

button.btn_default_r5_line {
  width: 100%;
  max-width: 80px;
  height: 30px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid #00BF8E;
  font-weight: bold;
  color: #00BF8E;
  white-space: nowrap;
  text-align: center;
  background-color: #fff;
}

a.btn_default_r5_line {
  display: inline-block;
  height: 30px;
  line-height: 28px;
  padding: 0 10px;
  border-radius: 5px;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  background-color: #fff;
}

.btn_default_r5_line[data-line=gray] {
  max-width: 100px;
  border: 1px solid #757575;
  color: #757575;
}

.btn_default_r5_line[data-line=red] {
  border: 1px solid #FA4163;
  color: #FA4163;
}

.btn_default_r5_line[data-line=graysm] {
  max-width: 70px;
  line-height: 28px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid #757575;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  color: #757575;
}

.btn_gray {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #ccc;
  font-weight: bold;
  color: #fff;
}

.btn_lg {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #00BF8E;
  font-size: 17px;
  font-weight: bold;
  color: #fff;
}

.btn_lg_line {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #00BF8E;
  font-size: 17px;
  font-weight: bold;
  color: #00BF8E;
}

.btn_lg_gray {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #ccc;
  font-size: 17px;
  font-weight: bold;
  color: #fff;
}

.btn_blue {
  width: 100%;
  height: 50px;
  border: 1px solid #4A7EFE;
  border-radius: 10px;
  font-weight: bold;
  color: #4A7EFE;
}

.btn_back {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  display: inline-block;
  width: 12px;
  height: 22px;
  background: url(@assets/img/arrow_left_bl_thick.png) no-repeat center/contain;
  vertical-align: middle;
}

.btn_next {
  display: inline-block;
  width: 13px;
  height: 10px;
  background: url(@assets/img/arrow_right_bl_thick.png) no-repeat center/contain;
}

.btn_search {
  display: inline-block;
  width: 19px;
  height: 19px;
  background: url(@assets/img/icon_search_bl.png) no-repeat center/contain;
}

.btn_search_gray {
  width: 17px;
  height: 17px;
  background: url(@assets/img/icon_search_gray.png) no-repeat center/contain;
}

.btn_like {
  width: 19px;
  height: 17px;
  background: url(@assets/img/icon_heart.png) no-repeat center/contain;
}

.btn_liked {
  background: url(@assets/img/icon_heart_fill.png) no-repeat center/contain;
}

.btn_share {
  width: 18px;
  height: 18px;
  background: url(@assets/img/icon_share_bl.png) no-repeat center/contain;
}

.btn_clear {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url(@assets/img/img_clear.png) no-repeat center/contain;
  border: 0;
  vertical-align: middle;
  cursor: pointer;
}

.btn_mission_back {
  position: fixed;
  right: 15px;
  bottom: 20px;
  display: inline-block;
  width: 37px;
  height: 37px;
  background: url(@assets/img/img_m_back.png) no-repeat center/contain;
}

.btn_mission_home {
  position: fixed;
  right: 15px;
  bottom: 20px;
  display: inline-block;
  width: 37px;
  height: 37px;
  background: url(@assets/img/tab_home.svg) no-repeat center/contain;
}

.btn_mission_leave {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: url(@assets/img/img_close_wh.png) no-repeat center/11px #757575;
}

.btn_modal_close {
  width: 11px;
  height: 11px;
  background: url(@assets/img/img_close_green.png) no-repeat center/contain;
}

.btn_count_minus,
.btn_count_add {
  width: 24px;
  height: 24px;
}

.btn_count_minus {
  background: url(@assets/img/img_minus.png) no-repeat center/contain;
}

.btn_count_add {
  background: url(@assets/img/img_add.png) no-repeat center/contain;
}

.btn_pop_yes {
  display: inline-block;
  width: calc(50% - 10px);
  height: 50px;
  line-height: 48px;
  font-weight: bold;
  border: 1px solid #00BF8E;
  border-radius: 10px;
  color: #00BF8E;
}

.btn_pop_no {
  display: inline-block;
  width: calc(50% - 10px);
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  border-radius: 10px;
  background-color: #00BF8E;
  text-align: center;
  color: #fff;
}

.divide_thick {
  display: inline-block;
  width: 100%;
  height: 8px;
  background-color: #F1F1F2;
}

.colblue {
  color: #4A7EFE;
}

.colgreen {
  color: #00BF8E;
}

.colred {
  color: #FA4163;
}

.coldg {
  color: #757575;
}

.code_countdown {
  color: #ccc;
}

.undline {
  text-decoration: underline;
}

/* ======= COMMON 끝 ======= */
/* ======= 헤더 & 하단 ======= */
/* 헤더 */
.header_wrap {
  position: relative;
}
.header_wrap #header {
  position: fixed;/* 1222 추가 */
  width: 100%;
  max-width: 1000px;
  height: 80px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  background-color: #fff;
}
.header_wrap #header .header_left .logo.off {
  display: none;
}
.header_wrap #header .header_left .btn_back.off {
  display: none;
}
.header_wrap #header .logo img {
  width: 100%;
  max-width: 106px;
}
.header_wrap #header .header_right {
  display: flex;
  column-gap: 17px;
  align-items: center;
}
.header_wrap #header .header_right .btn_notify {
  width: 17.5px;
  height: 19px;
  padding-right: 55px;
  background: url(@assets/img/icon_notfiy_bl.png) no-repeat center/contain;
}
.header_wrap #header .header_right .btn_gnb {
  position: fixed;
  width: 21px;
  height: 40px;
  transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
  user-select: none;
}
.header_wrap #header .header_right .btn_gnb span {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 21px;
  height: 2px;
  background-color: #000;
}
.header_wrap #header .header_right .btn_gnb span:nth-of-type(1) {
  top: 12.5px;
}
.header_wrap #header .header_right .btn_gnb span:nth-of-type(2) {
  top: 19px;
  width: 12.6px;
}
.header_wrap #header .header_right .btn_gnb span:nth-of-type(3) {
  top: 25.5px;
}
.header_wrap #header .header_right .btn_gnb:hover, .header_wrap #header .header_right .btn_gnb.active {
  transform: rotate(-90deg);
}
.header_wrap #header .header_right .btn_gnb:hover span:nth-of-type(1), .header_wrap #header .header_right .btn_gnb.active span:nth-of-type(1) {
  transform: rotate(45deg) translate(4.5px, 4.5px);
}
.header_wrap #header .header_right .btn_gnb:hover span:nth-of-type(2), .header_wrap #header .header_right .btn_gnb.active span:nth-of-type(2) {
  left: -5%;
  width: 0;
  opacity: 0;
}
.header_wrap #header .header_right .btn_gnb:hover span:nth-of-type(3), .header_wrap #header .header_right .btn_gnb.active span:nth-of-type(3) {
  transform: rotate(-45deg) translate(4.5px, -4.5px);
}
.header_wrap .header_nav_wrap {
  position: absolute;
  top: -400%;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #00BF8E;
}
.header_wrap .header_nav_wrap .header_nav {
  padding: 15px;
}
.header_wrap .header_nav_wrap .header_nav ul li {
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
  border-radius: 10px;
  background: url(@assets/img/arrow_next_gray_thin.png) no-repeat calc(100% - 15px) center/10px #fff;
}
.header_wrap .header_nav_wrap .header_nav ul li a {
  display: block;
}
.header_wrap .header_nav_wrap .header_nav ul li img {
  max-width: 24px;
}
.header_wrap .header_nav_wrap .header_nav ul li span {
  display: inline-block;
  width: 1px;
  height: 25px;
  margin: 0 15px;
  background-color: #ccc;
  vertical-align: middle;
}
.header_wrap .header_nav_wrap.active {
  height: 300px;
  top: 80px;
  z-index: 99;
}

.header_search_wrap {
  position: relative;
  border-bottom: 1px solid #E1E3E7;
}
.header_search_wrap .search_right {
  display: flex;
  width: 100%;
  padding-left: 27px;
  column-gap: 10px;
}
.header_search_wrap .search_right input {
  width: 90%;
}
.header_search_wrap .btn_search {
  display: inline-block;
  width: 19px;
  height: 19px;
  background: url(@assets/img/icon_search_bl.png) no-repeat center/contain;
}

.login_back_wrap {
  position: relative;
  height: 80px;
  line-height: 80px;
}

/* 하단 */
.bottom_menus {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  height: 74px;
  padding: 10px;
  box-shadow: 0 -2px 7px rgba(0, 0, 0, 0.1019607843);
  background-color: #fff;
}
.bottom_menus div:nth-of-type(1) .bottom_menu__img {
  background-image: url(@assets/img/tab_home.svg);
}
.bottom_menus div:nth-of-type(2) .bottom_menu__img {
  background-image: url(@assets/img/tab_rank.svg);
}
.bottom_menus div:nth-of-type(3) .bottom_menu__img {
  background-image: url(@assets/img/tab_order.svg);
}
.bottom_menus div:nth-of-type(4) .bottom_menu__img {
  background-image: url(@assets/img/tab_play.svg);
}
.bottom_menus div:nth-of-type(5) .bottom_menu__img {
  background-image: url(@assets/img/tab_my.svg);
}
.bottom_menus div:nth-of-type(1):hover .bottom_menu__img,
.bottom_menus div:nth-of-type(1) > .active .bottom_menu__img {
  background-image: url(@assets/img/tab_home_green.svg);
}
.bottom_menus div:nth-of-type(2):hover .bottom_menu__img,
.bottom_menus div:nth-of-type(2) > .active .bottom_menu__img {
  background-image: url(@assets/img/tab_rank_green.svg);
}
.bottom_menus div:nth-of-type(3):hover .bottom_menu__img,
.bottom_menus div:nth-of-type(3) > .active .bottom_menu__img {
  background-image: url(@assets/img/tab_order_green.svg);
}
.bottom_menus div:nth-of-type(4):hover .bottom_menu__img,
.bottom_menus div:nth-of-type(4) > .active .bottom_menu__img {
  background-image: url(@assets/img/tab_play_green.svg);
}
.bottom_menus div:nth-of-type(5):hover .bottom_menu__img,
.bottom_menus div:nth-of-type(5) > .active .bottom_menu__img {
  background-image: url(@assets/img/tab_my_green.svg);
}
.bottom_menus .bottom_menu {
  width: 20%;
  text-align: center;
  font-size: 12px;
  color: #757575;
}
.bottom_menus .bottom_menu:hover,
.bottom_menus .bottom_menu .active {
  color: #00BF8E;
}
.bottom_menus .bottom_menu .bottom_menu__img {
  display: inline-block;
  width: 100%;
  height: 100%;
  max-width: 24px;
  max-height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.register_bottom {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 15px);
  max-width: 1000px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.register_bottom small {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  color: #757575;
}
.register_bottom .btn_lg_gray:hover {
  background-color: #00BF8E;
  color: #fff;
}

.register_done_bottom {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 15px);
  max-width: 1000px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.mission_bottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  padding: 15px;
  column-gap: 10px;
  background-color: #F1F1F2;
}
.mission_bottom .btn_lg {
  display: inline-block;
  width: 100%;
  line-height: 50px;
  text-align: center;
}
.mission_bottom .mission_pay {
  background-color: #FA4163;
}
.mission_bottom .mission_ing {
  background-color: #4A7EFE;
}

.pay_bottom,
.rewards_bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 15px 30px;
}

.pay_bottom .btn_lg,
.start_bottom .btn_lg,
.rewards_bottom .btn_lg {
  display: inline-block;
  width: 100%;
  line-height: 50px;
  text-align: center;
}
.pay_bottom .mission_enter.disabled,
.start_bottom .mission_enter.disabled,
.rewards_bottom .mission_enter.disabled {
  background-color: #757575;
  color: rgba(255, 255, 255, 0.5);
}

.play_bottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  display: flex;
  width: 100%;
  max-width: 1000px;
  padding: 20px 15px 30px;
  row-gap: 15px;
  flex-direction: column;
}
.play_bottom::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, transparent, 40%, rgba(255, 255, 255, 0.7));
  z-index: -1;
}
.play_bottom > div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.play_bottom .hint_count {
  display: inline-block;
  width: auto;
  height: 100%;
  padding: 5px 10px;
  border-radius: 25px;
  background-color: #4A7EFE;
  text-align: center;
  color: #fff;
}
.play_bottom .mission_submit {
  width: calc(100% - 60px);
  line-height: 50px;
  text-align: center;
}

/* ======= 헤더 & 탭 끝 ======= */
/* ======= 모달 ======= */
.modal-dialog {
  max-width: 80%;
  margin: 0 auto;
}

.modal-content {
  width: 100%;
  border-radius: 10px;
}

.modal-body {
  position: relative;
  min-height: 160px;
}
.modal-body > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.modal-body .modal_text_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  justify-content: center;
  text-align: center;
  word-break: keep-all;
}
.modal-body small {
  color: #757575;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.modal-term {
  height: 100%;
  max-height: 60vh;
  padding: 20px 15px 20px;
  overflow-y: auto;
}
.modal-term small {
  display: block;
}

#detail_share .modal-body > div {
  padding: 15px;
}
#detail_share .detail_share_header {
  display: flex;
  justify-content: space-between;
}
#detail_share .detail_share_links {
  display: flex;
  justify-content: space-between;
  padding: 20px 18px;
  align-items: center;
  text-align: center;
}
#detail_share .detail_share_links img {
  display: block;
  max-width: 45px;
  max-height: 45px;
  margin-bottom: 8px;
}
#detail_share .detail_share_links small {
  color: #757575;
}

#mission_img_wide .btn_modal_close {
  position: fixed;
  top: 50px;
  right: 15px;
  background: url(@assets/img/img_close_wh.png) no-repeat center/contain;
}
#mission_img_wide .modal-dialog {
  max-width: 100%;
}
#mission_img_wide .modal-content {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}
#mission_img_wide .modal-content img {
  width: 100%;
  max-height: 50vh;
}

#mission_success .modal-backdrop::after {
  content: "";
  position: fixed;
  bottom: 20vh;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  display: inline-block;
  width: 100px;
  height: 100px;
  background: url(@assets/img/img_success.png) no-repeat center/contain;
  filter: hue-rotate(12deg) saturate(5);
}

#mission_winners .modal-body {
  max-height: 60vh;
  padding: 15px;
  overflow-y: auto;
}
#mission_winners .pop_winners_wrap li {
  display: flex;
  padding: 17px 3px;
  column-gap: 15px;
  border-bottom: 1px solid #E1E3E7;
  align-items: center;
}
#mission_winners .pop_winners_wrap li .rank_list__no {
  color: #00BF8E;
}
#mission_winners .pop_winners_wrap li .rank_list__img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
#mission_winners .pop_winners_wrap li .rank_list__text strong {
  display: block;
  padding-bottom: 5px;
}

/* ======= 모달 끝 ======= */
/* ======= 바디 ======= */
#root > .container {
  padding-top: 80px; /*1222 추가 */
}
.container {
  width: 100%;
  overflow-x: hidden;
}
.container .container_inner {
  padding: 0 15px;
}
.container .container_fit {
  padding-bottom: 99px;
}
.container .input_default {
  width: 100%;
  height: 50px;
  padding: 0 15px;
}
.container .input_default:hover, .container .input_default:focus {
  border: 1px solid #00BF8E;
}
.container .input_clear {
  position: relative;
}
.container .input_clear .btn_clear {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url(@assets/img/img_clear.png) no-repeat center/contain;
  border: 0;
  cursor: pointer;
}
.container .select_default {
  width: 100%;
  height: 50px;
  padding: 0 15px;
}

.login_full_container {
  background-color: #4A7EFE;
  color: #fff;
}
.login_full_container .container {
  position: relative;
  height: 100vh;
  padding: 100px 15px 0 15px;
}
.login_full_container .container .login_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login_full_container .container .login_top img {
  max-width: 95px;
}
.login_full_container .container .login_methods {
  margin-bottom: 40px;
  font-weight: bold;
  text-align: center;
}
.login_full_container .container .login_methods li {
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 10px;
  background-position: 15px center;
  background-size: 20px;
  background-repeat: no-repeat;
  color: #000;
}
.login_full_container .container .login_methods li:nth-of-type(1) {
  background-image: url(@assets/img/icon_kakao.svg);
}
.login_full_container .container .login_methods li:nth-of-type(2) {
  background-image: url(@assets/img/icon_naver.svg);
  color: #03C75A;
}
/* .login_full_container .container .login_methods li:nth-of-type(3) {
  background-image: url(@assets/img/icon_fb.svg);
  color: #1877F2;
}
.login_full_container .container .login_methods li:nth-of-type(4) {
  background-image: url(@assets/img/icon_apple.svg);
} */
.login_full_container .container .login_methods li:nth-of-type(3) {
  color: #FA4163;
}
.login_full_container .container .login_bottom .undline {
  display: block;
  margin-bottom: 18px;
  font-weight: bold;
  text-align: center;
}
.login_full_container .container .login_bottom small {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.paid_container {
  position: absolute;
  top: 35%;
  width: 100%;
  text-align: center;
}
.paid_container img {
  max-width: 50px;
  max-height: 50px;
  margin-bottom: 20px;
}
.paid_container h2 {
  margin-bottom: 30px;
}
.paid_container .btn_lg {
  margin-bottom: 10px;
}
.paid_container .btn_lg,
.paid_container .btn_lg_line {
  display: inline-block;
  line-height: 50px;
  text-align: center;
}

.mission_team_item_container {
  /* max-height: 60vh; */
  padding-bottom: 200px;
  /* overflow-y: scroll; */
}

.double_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}
.double_wrap div {
  width: calc(50% - 7.5px);
}

.triple_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}
.triple_wrap div {
  width: calc(33.33333% - 5px);
}

.quarter_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  row-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}
.quarter_wrap div {
  width: calc(50% - 5px);
}

.toggle_sibling {
  width: 100%;
  margin-bottom: 15px;
  background: url(@assets/img/arrow_up_gray_thin.png) no-repeat calc(100% - 15px) center/11px;
  text-align: left;
}
.toggle_sibling.active {
  background: url(@assets/img/arrow_down_gray_thin.png) no-repeat calc(100% - 15px) center/11px;
}

.toggle_load {
  width: 100%;
  padding-right: 1.5em;
  background: url(@assets/img/arrow_down_gray_thin.png) no-repeat right center/11px;
  text-align: center;
}
.toggle_load.active {
  background: url(@assets/img/arrow_up_gray_thin.png) no-repeat right center/11px;
}

/* ======= 바디 끝 ======= */
/* ======= 서브 ======= */
/* 로그인 및 가입*/
#login_email {
  margin-bottom: 20px;
}

.login_email_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: #757575;
}

.login_find_tab {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;
}
.login_find_tab h2 {
  margin-bottom: 60px;
}
.login_find_tab a {
  display: inline-block;
  width: 50%;
  padding-bottom: 15px;
  border-bottom: 2px solid #E1E3E7;
  text-align: center;
}
.login_find_tab a.on {
  border-bottom: 2px solid #4A7EFE;
  font-weight: bold;
  color: #4A7EFE;
}

.login_find_id h2 {
  padding-bottom: 60px;
}
.login_find_id fieldset > div {
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
}
.login_find_id fieldset > div button {
  max-width: 80px;
}
.login_find_id .code_countdown {
  position: absolute;
  left: calc(100% - 135px);
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.login_find_id #resend_code {
  color: #757575;
}
.login_find_id #resend_code img {
  width: 8px;
  margin-top: -2px;
  margin-left: 5px;
}

.login_find_id_result_wrap p {
  padding: 100px 0;
  text-align: center;
}
.login_find_id_result_wrap p span {
  color: #FA4163;
}

.term_chkall_wrap {
  width: 100%;
  height: 50px;
  padding: 14px 15px;
  margin-bottom: 15px;
  background-color: #F5F7F9;
  border-radius: 10px;
  font-weight: bold;
}

.term_chk_wrap {
  position: relative;
  width: 100%;
  padding: 14px 15px;
}
.term_chk_wrap .pop_term {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  width: 12px;
  height: 16px;
  background: url(@assets/img/arrow_right_gray_thin.png) no-repeat center/contain;
}

#register_account fieldset:not(.register_account_optional) label,
#edit_account fieldset:not(.register_account_optional) label,
#delete_account fieldset:not(.register_account_optional) label,
#mission_rewards fieldset:not(.register_account_optional) label {
  display: block;
  padding-bottom: 10px;
  font-size: 13px;
}
#register_account fieldset:not(.register_account_optional) input,
#register_account fieldset:not(.register_account_optional) select,
#edit_account fieldset:not(.register_account_optional) input,
#edit_account fieldset:not(.register_account_optional) select,
#delete_account fieldset:not(.register_account_optional) input,
#delete_account fieldset:not(.register_account_optional) select,
#mission_rewards fieldset:not(.register_account_optional) input,
#mission_rewards fieldset:not(.register_account_optional) select {
  margin-bottom: 15px;
}
#register_account fieldset .input_clear,
#edit_account fieldset .input_clear,
#delete_account fieldset .input_clear,
#mission_rewards fieldset .input_clear {
  margin-bottom: 15px;
}
#register_account fieldset .input_clear input,
#edit_account fieldset .input_clear input,
#delete_account fieldset .input_clear input,
#mission_rewards fieldset .input_clear input {
  margin-bottom: 0;
}
#register_account .register_account_optional_bg,
#edit_account .register_account_optional_bg,
#delete_account .register_account_optional_bg,
#mission_rewards .register_account_optional_bg {
  padding: 40px 0 25px;
  background-color: #F5F7F9;
}
#register_account .register_account_optional_bg .register_account_optional select,
#edit_account .register_account_optional_bg .register_account_optional select,
#delete_account .register_account_optional_bg .register_account_optional select,
#mission_rewards .register_account_optional_bg .register_account_optional select {
  margin-bottom: 15px;
}
#register_account .register_account_optional_bg .double_wrap,
#edit_account .register_account_optional_bg .double_wrap,
#delete_account .register_account_optional_bg .double_wrap,
#mission_rewards .register_account_optional_bg .double_wrap {
  margin-bottom: 20px;
  font-size: 13px;
}
#register_account .register_account_optional_bg .double_wrap div,
#edit_account .register_account_optional_bg .double_wrap div,
#delete_account .register_account_optional_bg .double_wrap div,
#mission_rewards .register_account_optional_bg .double_wrap div {
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  border: 1px solid #E1E3E7;
  border-radius: 10px;
  background-color: #fff;
  color: #757575;
}
#register_account .register_account_optional_bg .double_wrap div input,
#edit_account .register_account_optional_bg .double_wrap div input,
#delete_account .register_account_optional_bg .double_wrap div input,
#mission_rewards .register_account_optional_bg .double_wrap div input {
  margin-right: 10px;
}
@media (max-width: 380px) {
  #register_account .register_account_optional_bg .double_wrap div,
#edit_account .register_account_optional_bg .double_wrap div,
#delete_account .register_account_optional_bg .double_wrap div,
#mission_rewards .register_account_optional_bg .double_wrap div {
    width: calc(50% - 2.5px);
    padding: 0 10px;
  }
  #register_account .register_account_optional_bg .double_wrap div input,
#edit_account .register_account_optional_bg .double_wrap div input,
#delete_account .register_account_optional_bg .double_wrap div input,
#mission_rewards .register_account_optional_bg .double_wrap div input {
    margin-right: 5px;
  }
}
#register_account small,
#edit_account small,
#delete_account small,
#mission_rewards small {
  display: block;
  margin-bottom: 20px;
  color: #757575;
}
#register_account textarea,
#edit_account textarea,
#delete_account textarea,
#mission_rewards textarea {
  width: 100%;
  height: 100%;
  padding: 15px;
  border: 1px solid #E1E3E7;
  border-radius: 10px;
  resize: vertical;
}

.register_done_contents {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  width: 100%;
  text-align: center;
}
.register_done_contents img {
  margin-bottom: 25px;
}
.register_done_contents .register_done_text {
  margin-bottom: 20px;
}

/* 메인 화면 */
.index_search_wrap {
  position: relative;
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border-radius: 10px;
  background-color: #F5F7F9;
}
.index_search_wrap input {
  width: calc(100% - 35px);
  height: 100%;
  background-color: transparent;
}
.index_search_wrap .btn_search_gray {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.index_welcome_note {
  position: relative;
  height: 100%;
  padding-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
}
.index_welcome_note .index_welcome_note__right {
  position: relative;
  top: -10px;
}
.index_welcome_note .index_welcome_note__right::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, transparent, 80%, rgb(255, 255, 255));
}
.index_welcome_note .index_welcome_note__right img {
  width: 100%;
  max-width: 120px;
}
.index_welcome_note h2 {
  margin-bottom: 15px;
}
.index_welcome_note a {
  color: #757575;
}
.index_welcome_note a img {
  max-width: 8px;
  margin-left: 10px;
}

.index_tip_slider_wrap {
  margin-bottom: 40px;
  border-radius: 10px;
  overflow: hidden;
}
.index_tip_slider_wrap .index_tip_slider {
  width: 100%;
  height: 82px;
  border-radius: 10px;
  overflow: hidden;
}
.index_tip_slider_wrap .index_tip_slider .slick-slide {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.index_tip_slider_wrap .index_tip_slider .slick-slide a {
  display: block;
  width: 100%;
  height: 100%;
}
.index_tip_slider_wrap .index_tip_slider .slick-slide a img {
  width: 100%;
  height: 82px;
}
.index_tip_slider_wrap .index_tip_slider .slick-dots {
  position: absolute;
  max-width: 50px;
  height: 26px;
  top: 15px;
  right: 15px;
  border-radius: 50px;
  text-align: center;
  font-size: 11px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.index_tip_slider_wrap .index_tip_slider .slick-dots li {
  display: none;
  margin: 0;
}
.index_tip_slider_wrap .index_tip_slider .slick-dots li.slick-active {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.index_tip_slider_wrap .index_tip_slider .slick-dots li.slick-active span {
  margin-top: -2px;
}

.index_quarter_wrap {
  margin-bottom: 40px;
}
.index_quarter_wrap label {
  width: calc(50% - 7.5px);
  height: 50px;
  line-height: 50px;
  color: #757575;
  border: 1px solid #E1E3E7;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 24px;
  background-color: #F5F7F9;
}
.index_quarter_wrap label:hover, .index_quarter_wrap label.active {
  background-color: #fff;
}
.index_quarter_wrap label strong {
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 49px;
}
.index_quarter_wrap .index_solo {
  background-image: url(@assets/img/icon_h1.png);
}
.index_quarter_wrap .index_solo:hover, .index_quarter_wrap .index_solo.active {
  border: 1px solid #00BF8E;
  background-image: url(@assets/img/icon_h1_green.png);
  color: #00BF8E;
}
.index_quarter_wrap .index_team {
  background-image: url(@assets/img/icon_h2.png);
}
.index_quarter_wrap .index_team:hover, .index_quarter_wrap .index_team.active {
  border: 1px solid #4A7EFE;
  background-image: url(@assets/img/icon_h2_blue.png);
  color: #4A7EFE;
}
.index_quarter_wrap .index_stamp {
  background-image: url(@assets/img/icon_h3.png);
}
.index_quarter_wrap .index_stamp:hover, .index_quarter_wrap .index_stamp.active {
  border: 1px solid #FA4163;
  background-image: url(@assets/img/icon_h3_red.png);
  color: #FA4163;
}
.index_quarter_wrap .index_spots {
  background-image: url(@assets/img/icon_h4.png);
}
.index_quarter_wrap .index_spots:hover, .index_quarter_wrap .index_spots.active {
  border: 1px solid #FF9400;
  background-image: url(@assets/img/icon_h4_yellow.png);
  color: #FF9400;
}

.index_quarter_contents input {
  /* display: none; */
}
.index_quarter_contents .index_mission_wrap {
  /* display: none; */
}
.index_quarter_contents input:checked + .index_mission_wrap {
  /* display: block; */
}

.index_mission_wrap {
  width: 100%;
  height: 100%;
}

.mission_items {
  display: flex;
  flex-direction: column;
}
.mission_items .mission_item:last-of-type {
  border-bottom: 0;
}
.mission_items .mission_item {
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #E1E3E7;
}
.mission_items .mission_item .img_wrap {
  display: block;
  width: 100%;
  height: 200px;
  margin-bottom: 15px;
}
.mission_items .mission_item .img_wrap img {
  width: 100%;
  max-width: 477px;
  height: 100%;
  border: 1px solid #E1E3E7;
  border-radius: 10px;
  overflow: hidden;
}
.mission_items .mission_item .mission_period_wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  color: #757575;
}
.mission_items .mission_item .mission_period_wrap strong {
  margin-right: 10px;
}
.mission_items .mission_item .mission_title {
  margin-bottom: 18px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.mission_items .mission_item .spot_title {
  margin-bottom: 10px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.mission_items .mission_item .mission_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mission_items .mission_item .mission_info small {
  color: #757575;
}
.mission_items .mission_item .mission_info small img {
  max-width: 18px;
  margin-right: 5px;
}
.mission_items .mission_item .mission_info .mission_price h3 {
  display: inline-block;
  color: #00BF8E;
}
.mission_items .mission_item .mission_info .mission_info_winner a {
  display: inline-block;
  width: auto;
  padding: 3px 10px;
  border: 1px solid #00BF8E;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  color: #00BF8E;
  cursor: pointer;
}

.load_more_wrap {
  width: 100px;
  margin: 0 auto;
  padding-bottom: 30px;
}

/* 검색 */
.search_history_wrap {
  padding: 20px 15px 15px;
}
.search_history_wrap .contents_subject {
  padding-bottom: 10px;
  font-weight: bold;
  color: #4A7EFE;
}
.search_history_wrap .search_keywords {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.search_history_wrap .search_keywords .search_keyword a {
  padding-right: 5px;
  font-weight: bold;
}

.search_cate_wrap {
  width: 100%;
  max-width: 1000px;
  padding: 20px 15px 16px;
  border-bottom: 1px solid #E1E3E7;
  white-space: nowrap;
  overflow-x: auto;
  font-weight: bold;
}
.search_cate_wrap a {
  padding: 0 7.5px;
  color: #ccc;
}
.search_cate_wrap a.active, .search_cate_wrap a:hover {
  color: #00BF8E;
}
.search_cate_wrap::-webkit-scrollbar {
  width: 100%;
  height: 1px;
  background-color: transparent;
}
.search_cate_wrap::-webkit-scrollbar-thumb {
  width: 20%;
  height: 1px;
  background-color: transparent;
}

.search_result_wrap {
  width: 100%;
}
.search_result_wrap .container_inner {
  height: calc(60vh - 100px);
}
.search_result_wrap .container_inner > small {
  display: none;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #757575;
}
.search_result_wrap .container_inner .search_result_info {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 20px;
}
.search_result_wrap .container_inner .search_result_info #search_order {
  padding-right: 10px;
  background: url(@assets/img/arrow_down_gray_thin.png) no-repeat right center/11px;
  text-align: center;
}
.search_result_wrap .container_inner .search_result_info #search_order:hover, .search_result_wrap .container_inner .search_result_info #search_order:active {
  background: url(@assets/img/arrow_up_gray_thin.png) no-repeat right center/11px;
}

/* 미션 상세 */
.detail_countdown_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  margin: 0 10px 20px;
  border-radius: 10px;
  background-color: #00BF8E;
  color: #fff;
}
.detail_countdown_wrap .detail_count_down_left {
  white-space: nowrap;
}
.detail_countdown_wrap .detail_count_down_left img {
  max-width: 18px;
  max-height: 18px;
  margin-right: 10px;
}
@media (max-width: 380px) {
  .detail_countdown_wrap .detail_count_down_left img {
    margin-right: 2px;
  }
}
.detail_countdown_wrap .detail_countdown_right {
  opacity: 0.7;
}

.detail_cate {
  margin-bottom: 11px;
  color: #00BF8E;
}
.detail_cate img {
  max-height: 20px;
  margin-right: 8px;
}
.detail_cate[data-name=team] {
  color: #4A7EFE;
}
.detail_cate[data-name=stamp] {
  color: #FA4163;
}
.detail_cate[data-name=spots] {
  color: #FF9400;
}

.detail_title {
  margin-bottom: 15px;
}

.detail_mission_info {
  display: flex;
  margin-bottom: 15px;
  line-height: 2.5;
  font-size: 13px;
  flex-wrap: wrap;
}
.detail_mission_info dt {
  width: 100px;
  font-weight: bold;
  color: #757575;
}
.detail_mission_info dd {
  width: calc(100% - 115px);
}
.detail_mission_info dd:last-of-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail_spot_info {
  display: flex;
  margin-bottom: 15px;
  line-height: 2.5;
  font-size: 13px;
  flex-wrap: wrap;
}
.detail_spot_info dt {
  width: 55px;
  font-weight: bold;
  color: #757575;
}
.detail_spot_info dd {
  width: calc(100% - 65px);
}
.detail_spot_info dd:last-of-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail_result {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid #E1E3E7;
}
.detail_result .triple_wrap div {
  display: flex;
  justify-content: space-between;
  width: calc(33.33333% - 20px);
  position: relative;
}
.detail_result .triple_wrap div::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: calc(100% + 15px);
  display: inline-block;
  width: 1px;
  height: 13px;
  background-color: #E1E3E7;
}
.detail_result .triple_wrap div:last-of-type::after {
  display: none;
}

.detail_img_slider_wrap {
  margin-bottom: 30px;
}
.detail_img_slider_wrap .detail_img_slider {
  width: 100%;
  /* height: 190px; */
  height: 100%;
}
.detail_img_slider_wrap .detail_img_slider .slick-slide {
  border-radius: 10px;
  overflow: hidden;
}
.detail_img_slider_wrap .detail_img_slider .slick-slide img {
  width: 100%;
  height: 100%;
  max-height: 190px;
  border-radius: 10px;
  object-fit: cover;
}
.detail_img_slider_wrap .detail_img_slider .slick-dots {
  position: absolute;
  width: 50px;
  height: 26px;
  bottom: 19px;
  right: 16px;
  border-radius: 25px;
  text-align: center;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.detail_img_slider_wrap .detail_img_slider .slick-dots li {
  display: none;
}
.detail_img_slider_wrap .detail_img_slider .slick-dots li.slick-active {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;
}
.detail_img_slider_wrap .detail_img_slider .slick-dots li.slick-active span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  display: inline-block;
  width: 100%;
}

.detail_rewards_slider_wrap {
  margin-bottom: 20px;
}
/* .detail_rewards_slider_wrap .slick-list {
  padding: 0 20% 0 0 !important;
} */

.detail_mission_reward {
  width:33vw !important;
  padding: 0 4px;
  text-align: center;
}
.detail_mission_reward img {
  height:33vw;
  max-width: 100%;
  margin: 0 auto 10px;
  border: 1px solid #E1E3E7;
  border-radius: 10px;
}
.detail_mission_reward span {
  display: inline-block;
  text-align: center;
  word-break: keep-all;
}

.mission_contents .mission_content {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E1E3E7;
}
.mission_contents .mission_content .mission_content__title {
  position: relative;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}
.mission_contents .mission_content .mission_content__title img {
  max-width: 36px;
  max-height: 36px;
  margin-right: 10px;
}
.mission_contents .mission_content .mission_content__title span {
    display: inline-block;
    width: 36px;
    min-width: 36px;
    height: 36px;
    line-height: 38px;
    margin-right: 10px;
    background-image: url(@assets/img/img_d1_2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size:contain;
    text-align: center;
    font-family: 'GmarketSansMedium', 'Noto Sans KR', sans-serif;
    font-weight: 700;
    color:#fff;
    cursor: default;
  }
  .mission_contents .mission_content .mission_content__title span[data-num="2"],
  .mission_contents .mission_content .mission_content__title span[data-num="6"] {background-image: url(@assets/img/img_d2_2.png);}
  .mission_contents .mission_content .mission_content__title span[data-num="3"],
  .mission_contents .mission_content .mission_content__title span[data-num="7"] {background-image: url(@assets/img/img_d3_2.png);}
  .mission_contents .mission_content .mission_content__title span[data-num="4"],
  .mission_contents .mission_content .mission_content__title span[data-num="8"] {background-image: url(@assets/img/img_d4_2.png);}
.mission_contents .mission_content .mission_content__title h2 {
  width: 75%;
}
.mission_contents .mission_content .mission_content__title .btn_mission_back {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 15px;
}
.mission_contents .mission_content .mission_content__img {
  margin-bottom: 15px;
}
.mission_contents .mission_content .mission_content__img img {
  width: 100%;
  max-width: 477px;
}
.mission_contents .mission_content .mission_success_mark {
  display: none;
}
.mission_contents .mission_content.success {
  position: relative;
}
.mission_contents .mission_content.success *:not(.mission_success_mark) {
  filter: saturate(0) opacity(0.25);
  -webkit-filter: saturate(0) opacity(0.25);
  -ms-filter: saturate(0) opacity(0.25);
}
.mission_contents .mission_content.success .mission_success_mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  display: inline-block;
  width: 108px;
  height: 96px;
  z-index: 2;
}

.mission_start_container {
  position: relative;
  width: 100%;
  height: calc(90vh - 100px);
  text-align: center;
}
.mission_start_container .mission_start_text {
  padding-top: 10vh;
  margin-bottom: 20px;
}
.mission_start_container .mission_start_text img {
  max-width: 50px;
  max-height: 50px;
  margin-bottom: 20px;
}
.mission_start_container .mission_start_text h2 {
  width: 60%;
  margin: 0 auto 30px;
  word-break: keep-all;
}
.mission_start_container .mission_start_text h3 {
  margin-bottom: 15px;
  color: #00BF8E;
}
.mission_start_container #form_mission_key input {
  margin-bottom: 15px;
}
.mission_start_container #form_mission_key input::placeholder {
  text-align: center;
}
.mission_start_container .mission_start_bottom {
  padding-top: 30px;
  text-align: center;
}
.mission_start_container .mission_start_bottom small {
  display: block;
  padding-bottom: 10px;
  color: #757575;
}
.mission_start_container .mission_start_bottom a {
  font-size: 13px;
  color: #4A7EFE;
}

.mission_pay_notice {
  margin-bottom: 20px;
}
.mission_pay_notice p {
  display: flex;
  column-gap: 10px;
}
.mission_pay_notice p .colblue {
  font-weight: bold;
  font-size: 17px;
}

.mission_pay_key_count_wrap {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 20px;
  align-items: center;
  border: 1px solid #E1E3E7;
  border-radius: 10px;
  background-color: #F5F7F9;
}
.mission_pay_key_count_wrap .mission_pay_key_count {
  display: flex;
}
.mission_pay_key_count_wrap .mission_pay_key_count strong {
  margin: 0 20px;
}

.mission_pay_sum_wrap {
  display: flex;
  justify-content: space-between;
  line-height: 2;
  flex-wrap: wrap;
  align-items: center;
}
.mission_pay_sum_wrap dt {
  width: 100px;
  font-weight: bold;
}
.mission_pay_sum_wrap dd {
  width: calc(100% - 115px);
  text-align: right;
}
.mission_pay_sum_wrap dd h2 {
  display: inline-block;
}

/* 미션 참여 */
.team_start_wrap {
  width: 100%;
  height: 100%;
}
.team_start_wrap .team_start_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.team_start_wrap .team_start_text label {
  display: inline-block;
  border-radius: 5px;
  width: 42px;
  padding: 4px 0;
  margin-right: 5px;
  border: 1px solid #ff9400;
  font-weight: bold;
  text-align: center;
  color: #ff9400;
}
.team_start_wrap .team_start_text label[data-num="2"] {
  border: 1px solid #FA4163;
  color: #FA4163;
}
.team_start_wrap .team_start_text label[data-num="3"] {
  border: 1px solid #00BF8E;
  color: #00BF8E;
}
.team_start_wrap .team_start_text label[data-num="4"] {
  border: 1px solid #4A7EFE;
  color: #4A7EFE;
}
.team_start_wrap .team_start_text small {
  width: 80%;
  font-weight: bold;
  color: #757575;
}
.team_start_wrap .input_clear {
  margin-bottom: 20px;
}
.team_start_wrap .team_party_wrap {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #F5F7F9;
}

.mission_lists_countdown_wrap {
  padding: 15px;
  background-color: #F5F7F9;
  border-top: 1px solid #E1E3E7;
  border-bottom: 1px solid #E1E3E7;
}
.mission_lists_countdown_wrap .mission_countdown_wrap {
  margin-bottom: 15px;
}

.mission_progress_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mission_progress_wrap .mission_progress_bar {
  position: relative;
  width: calc(100% - 50px);
  height: 10px;
  border-radius: 25px;
  background-color: #E1E3E7;
}
.mission_progress_wrap .mission_progress_bar .mission_progress_fill {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: 0;
  width: 0;
  height: 100%;
  background-color: #00BF8E;
  border-radius: 25px;
}

.mission_list_contents .mission_content {
  padding-top: 20px;
}
.mission_list_contents .mission_content p {
  padding-bottom: 15px;
}
.mission_list_contents .mission_content .mission_content_btn_wrap {
  text-align: right;
}

.team_party_wrap li {
  display: flex;
  margin-bottom: 15px;
  column-gap: 15px;
  align-items: center;
}
.team_party_wrap li .team_party__img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.team_party_wrap li:last-of-type {
  margin-bottom: 0;
}

.mission_alert {
  display: none;
  padding: 11px 15px;
  background-color: #FA4163;
  font-size: 13px;
  color: #fff;
}
.mission_alert.active {
  position: sticky;
  top: 0;
  display: block;
  z-index: 2;
}

.mission_item_q {
  padding-bottom: 20px;
}
.mission_item_q .mission_mission {
  display: inline-block;
  width: auto;
  height: 100%;
  padding: 3px 10px;
  margin-top: 20px;
  border: 1px solid #FA4163;
  border-radius: 5px;
  font-weight: bold;
  font-size: 13px;
  color: #FA4163;
}
.mission_item_q h2 {
  padding: 15px 0 10px;
}

#mission_item_type1 {
  position: relative;
}

.mission_item_type1 .item_type1__img {
  margin-bottom: 20px;
  border-radius: 10px;
}
.mission_item_type1 .item_type1__img img {
  max-height: 110px;
}

.mission_item_type2 .mission_attach {
  display: block;
  width: 100%;
  height: 50%;
  line-height: 48px;
  border: 1px solid #00BF8E;
  border-radius: 10px;
  text-align: center;
  color: #00BF8E;
}
.mission_item_type2 .mission_attach img {
  max-width: 21px;
}
.mission_item_type2 input[type=file] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  left: -9999px;
  font-size: 0;
  visibility: hidden;
}

.mission_item_type3 label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  line-height: 48px;
  padding: 0 15px;
  margin-bottom: 10px;
  border: 1px solid #E1E3E7;
  border-radius: 10px;
}
.mission_item_type3 label.active {
  border: 1px solid #00BF8E;
}

.mission_item_type4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mission_item_type4 input {
  width: calc(50% - 6px);
  min-height: 155px;
  border: 1px solid #E6E8EC;
  border-radius: 10px;
}
.mission_item_type4 input:first-of-type {
  background: url(@assets/img/img_correct.png) no-repeat center/50px;
}
.mission_item_type4 input:first-of-type:checked {
  border: 1px solid #00BF8E;
}
.mission_item_type4 input:last-of-type {
  background: url(@assets/img/img_wrong.png) no-repeat center/50px;
}
.mission_item_type4 input:last-of-type:checked {
  border: 1px solid #FA4163;
}

/* 스탬프 투어 */
.mission_stamp_wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: 100%;
  width: 330px;
  height: 330px;
  margin: 20px auto 30px;
}

.mission_stamp_wrap.grid-col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.mission_stamp_wrap.grid-col-3 {
  grid-template-columns: repeat(3, 1fr);
}


.mission_stamp_wrap .mission_stamp_quarter {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  border: 1px solid #E1E3E7;
  background-image: url(@assets/img/img_stamp1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200%;
}

.mission_stamp_wrap .mission_stamp_nine {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  border: 1px solid #E1E3E7;
  background-image: url(@assets/img/img_stamp1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300%;
}

.mission_stamp_wrap .bg1 {
  background-image: url(@assets/img/img_stamp1.png);
}
.mission_stamp_wrap .bg2 {
  background-image: url(@assets/img/img_stamp2.png);
}
.mission_stamp_wrap .bg3 {
  background-image: url(@assets/img/img_stamp3.png);
}
.mission_stamp_wrap .bg4 {
  background-image: url(@assets/img/img_stamp4.png);
}
.mission_stamp_wrap .bg5 {
  background-image: url(@assets/img/img_stamp5.png);
}
.mission_stamp_wrap .bg6 {
  background-image: url(@assets/img/img_stamp6.png);
}
.mission_stamp_wrap .bg7 {
  background-image: url(@assets/img/img_stamp7.png);
}
.mission_stamp_wrap .bg8 {
  background-image: url(@assets/img/img_stamp8.png);
}

.mission_stamp_wrap .mission_stamp_nine:nth-of-type(1) {
  background-position: left top;
  border-right: 0;
  border-bottom: 0;
}
.mission_stamp_wrap .mission_stamp_nine:nth-of-type(2) {
  background-position: center top;
  border-right: 0;
  border-bottom: 0;
}
.mission_stamp_wrap .mission_stamp_nine:nth-of-type(3) {
  background-position: right top;
  border-bottom: 0;
}
.mission_stamp_wrap .mission_stamp_nine:nth-of-type(4) {
  background-position: left center;
  border-right: 0;
  border-bottom: 0;
}
.mission_stamp_wrap .mission_stamp_nine:nth-of-type(5) {
  background-position: center;
  border-right: 0;
  border-bottom: 0;
}
.mission_stamp_wrap .mission_stamp_nine:nth-of-type(6) {
  background-position: right center;
  border-bottom: 0;
}
.mission_stamp_wrap .mission_stamp_nine:nth-of-type(7) {
  background-position: left bottom;
  border-right: 0;
}
.mission_stamp_wrap .mission_stamp_nine:nth-of-type(8) {
  background-position: center bottom;
  border-right: 0;
}
.mission_stamp_wrap .mission_stamp_nine:nth-of-type(9) {
  background-position: right bottom;
}
.mission_stamp_wrap .mission_stamp_quarter:nth-of-type(1) {
  background-position: left top;
  border-right: 0;
  border-bottom: 0;
}
.mission_stamp_wrap .mission_stamp_quarter:nth-of-type(2) {
  background-position: right top;
  border-bottom: 0;
}
.mission_stamp_wrap .mission_stamp_quarter:nth-of-type(3) {
  background-position: left bottom;
  border-right: 0;
}
.mission_stamp_wrap .mission_stamp_quarter:nth-of-type(4) {
  background-position: right bottom;
}
.mission_stamp_wrap > div strong {
  display: grid;
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  background-color: rgba(117, 117, 117, 0.75);
  color: #fff;
  padding: .5em;
  box-sizing: border-box;
}
.mission_stamp_wrap > div.active strong {
  visibility: hidden;
}

/* 구매 내역 */
.order_lists h2 {
  padding: 0 15px 20px;
}
.order_lists .order_list:nth-of-type(2n) {
  background-color: #F5F7F9;
}
.order_lists .order_list {
  padding: 20px 0;
  border-bottom: 1px solid #E1E3E7;
}
.order_lists .order_list .order_list_inner {
  padding: 0 15px;
}
.order_lists .order_list .order_status_wrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  align-items: center;
}
.order_lists .order_list .order_status_wrap .order_status strong {
  display: inline-block;
  padding-right: 10px;
}
.order_lists .order_list .order_cate {
  padding-bottom: 15px;
  color: #757575;
}
.order_lists .order_list .order_contents_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.order_lists .order_list .order_contents_wrap .order_contents_img_wrap img {
  width: 100%;
  max-width: 106px;
  height: 100%;
  max-height: 106px;
}
.order_lists .order_list .order_contents_wrap .order_contents_info_wrap {
  position: relative;
  width: calc(100% - 126px);
}
.order_lists .order_list .order_contents_wrap .order_contents_info_wrap .order_contents_info_pax {
  position: absolute;
  top: -35px;
  color: #757575;
}
.order_lists .order_list .order_contents_wrap .order_contents_info_wrap h4 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 13px;
}
.order_lists .order_list .order_contents_wrap .order_contents_info_wrap div {
  display: flex;
  justify-content: space-between;
}
.order_lists .order_list .order_contents_wrap .order_contents_info_wrap div h3 {
  display: inline-block;
}
.order_lists .order_list .order_contents_wrap .order_contents_info_wrap div:first-of-type {
  padding-bottom: 10px;
}
.order_lists .order_list .order_mission_status_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order_lists .order_list .order_mission_status_wrap div img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.order_lists .order_list .order_mission_status_wrap .mission_status_done {
  margin-right: 20px;
  color: #4A7EFE;
}
.order_lists .order_list .order_mission_status_wrap .mission_status_fail {
  color: #757575;
}

.order_detail_status_wrap {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order_detail_lists .order_list {
  border-bottom: 0;
}
.order_detail_lists .order_detail_subject {
  padding-bottom: 15px;
  font-size: 13px;
  font-weight: bold;
  color: #757575;
}
.order_detail_lists .order_detail_history {
  display: flex;
  padding-bottom: 20px;
  line-height: 2;
  font-size: 13px;
  flex-wrap: wrap;
}
.order_detail_lists .order_detail_history dt {
  width: 100px;
}
.order_detail_lists .order_detail_history dd {
  width: calc(100% - 120px);
}
.order_detail_lists .order_detail_key_wrap .container_inner {
  padding: 20px 15px;
}
.order_detail_lists .order_detail_key_wrap .key_wrap:first-of-type {
  border-top: 1px solid #E1E3E7;
}
.order_detail_lists .order_detail_key_wrap .key_none {
  display: block;
  padding: 50px 0;
  text-align: center;
  border-top: 1px solid #E1E3E7;
  border-bottom: 1px solid #E1E3E7;
  background-color: #F5F7F9;
  color: #757575;
}
.order_detail_lists .order_detail_key_wrap .key_wrap {
  padding: 20px 15px;
  border-bottom: 1px solid #E1E3E7;
  background-color: #F5F7F9;
}
.order_detail_lists .order_detail_key_wrap .key_wrap .key_copy_wrap {
  position: relative;
}
.order_detail_lists .order_detail_key_wrap .key_wrap .key_copy_wrap input {
  margin-bottom: 10px;
  text-align: center;
}
.order_detail_lists .order_detail_key_wrap .key_wrap .key_copy_wrap button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 22px;
  width: 100%;
  max-width: 61px;
  height: 30px;
  border-radius: 5px;
  background-color: #4A7EFE;
  font-weight: bold;
  color: #fff;
}
.order_detail_lists .order_detail_key_wrap .key_wrap .key_send_wrap {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}
.order_detail_lists .order_detail_key_wrap .key_wrap .key_send_wrap button, .order_detail_lists .order_detail_key_wrap .key_wrap .key_send_wrap a {
  display: inline-block;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
}
.order_detail_lists .order_detail_key_wrap .key_wrap .key_send_wrap .btn_key_copy {
  background-color: #FA4163;
}
.order_detail_lists .order_detail_key_wrap .key_wrap .key_send_wrap .btn_key_play {
  background-color: #4A7EFE;
}
.order_detail_lists .order_detail_key_wrap .key_wrap .key_send_wrap .btn_send_kakao {
  line-height: 30px;
  padding: 0 10px 0 15px;
  background: url(@assets/img/icon_kakao.svg) no-repeat 10px center/18px #FEE500;
  text-align: right;
  color: #000;
}
@media (max-width: 380px) {
  .order_detail_lists .order_detail_key_wrap .key_wrap .key_send_wrap .btn_send_kakao {
    padding: 0 10px;
    text-align: center;
    background: #FEE500;
  }
}

/* 랭킹 */
.rank_search_wrap {
  display: flex;
  width: 100%;
  padding: 15px;
  align-items: center;
  column-gap: 10px;
  border-bottom: 1px solid #E1E3E7;
}
.rank_search_wrap input {
  width: calc(100% - 50px);
}

.rank_mine_wrap {
  padding: 20px 15px;
}
.rank_mine_wrap button strong {
  color: #000;
}
.rank_mine_wrap .rank_mine {
  display: flex;
  padding: 10px 15px;
  column-gap: 15px;
  align-items: center;
  border-radius: 10px;
  background-color: #00BF8E;
}
.rank_mine_wrap .rank_mine .rank_mine__img {
  width: 60px;
  height: 60px;
  border: 2px solid #ff9400;
  border-radius: 50%;
  overflow: hidden;
}
.rank_mine_wrap .rank_mine .rank_mine__img img {background-color: #fff;}
.rank_mine_wrap .rank_mine .rank_mine__text {
  line-height: 2.2;
  color: #fff;
}
.rank_mine_wrap .rank_mine .rank_mine__text h3, .rank_mine_wrap .rank_mine .rank_mine__text h4 {
  display: inline-block;
}
.rank_mine_wrap .rank_mine .rank_mine__text h4 {
  padding-right: 10px;
  font-weight: 500;
}
.rank_mine_wrap .rank_mine.active {
  display: none;
}

.rank_lists .rank_list {
  display: flex;
  padding: 17px 3px;
  column-gap: 15px;
  border-bottom: 1px solid #E1E3E7;
  align-items: center;
}
.rank_lists .rank_list .rank_list__no {
  color: #00BF8E;
}
.rank_lists .rank_list .rank_list__img img {
  width: 40px;
  min-width:40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.rank_lists .rank_list .rank_list__text strong {
  display: block;
  padding-bottom: 5px;
}
.rank_lists .rank_list:nth-of-type(1),
.rank_lists .rank_list:nth-of-type(2),
.rank_lists .rank_list:nth-of-type(3) {
  padding: 20px 10px 16px;
}
.rank_lists .rank_list:nth-of-type(1) .rank_list__no,
.rank_lists .rank_list:nth-of-type(2) .rank_list__no,
.rank_lists .rank_list:nth-of-type(3) .rank_list__no {
  display: none;
}
.rank_lists .rank_list:nth-of-type(1) .rank_list__img,
.rank_lists .rank_list:nth-of-type(2) .rank_list__img,
.rank_lists .rank_list:nth-of-type(3) .rank_list__img {
  position: relative;
}
.rank_lists .rank_list:nth-of-type(1) .rank_list__img img,
.rank_lists .rank_list:nth-of-type(2) .rank_list__img img,
.rank_lists .rank_list:nth-of-type(3) .rank_list__img img {
  width: 60px;
  height: 60px;
}
.rank_lists .rank_list:nth-of-type(1) .rank_list__img::before,
.rank_lists .rank_list:nth-of-type(2) .rank_list__img::before,
.rank_lists .rank_list:nth-of-type(3) .rank_list__img::before {
  content: "";
  position: absolute;
  top: -14px;
  left: -14px;
  display: inline-block;
  width: 36px;
  height: 36px;
}
.rank_lists .rank_list:nth-of-type(1) .rank_list__img::before {
  background: url(@assets/img/img_r1.png) no-repeat center/contain;
}
.rank_lists .rank_list:nth-of-type(2) .rank_list__img::before {
  background: url(@assets/img/img_r2.png) no-repeat center/contain;
}
.rank_lists .rank_list:nth-of-type(3) .rank_list__img::before {
  background: url(@assets/img/img_r3.png) no-repeat center/contain;
}

.rank_user_wrap {
  padding: 11px 0 20px;
}

.rank_mission_items .mission_title {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.rank_mission_items .mission_info_result {
  display: inline-block;
  width: auto;
  padding: 3px 15px;
  text-align: center;
  border: 1px solid #00BF8E;
  border-radius: 5px;
  font-weight: bold;
  color: #00BF8E;
}
.rank_mission_items .mission_info_result__fail {
  border: 1px solid #FA4163;
  color: #FA4163;
}

.mission_team_notice {
  padding: 11px 0 15px;
}

.mission_team_desc {
  display: inline-block;
  padding-bottom: 20px;
}

/* 마이페이지 */
.my_profile_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
}
.my_profile_wrap .my_profile_img_wrap {
  position: relative;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  
}
.my_profile_wrap .my_profile_img_wrap .my_profile__img {
  width: 83px;
  height: 83px;
  border-radius: 50%;
  overflow: hidden;
}
.my_profile_wrap .my_profile_img_wrap .my_profile__img img {
  width: 100%;
  height: 100%;
}
.my_profile_wrap .my_profile_img_wrap .my_profile__attach {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-image: linear-gradient(180deg, #00BF8E, #4A7EFE);
  overflow: hidden;
}
.my_profile_wrap .my_profile_img_wrap .my_profile__attach label {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: url(@assets/img/icon_media_wh.png) no-repeat center/21px;
  cursor: pointer;
}
.my_profile_wrap .my_profile_img_wrap .my_profile__attach input[type=file] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  left: -9999px;
  font-size: 0;
  visibility: hidden;
}
.my_profile_wrap .my_profile_info_wrap {
  width: calc(100% - 100px);
}
.my_profile_wrap .my_profile_info_wrap .undline {
  color: #757575;
}
.my_profile_wrap .my_profile_info_wrap .my_profile_info_links {
  display: flex;
  justify-content: space-between;
}

.my_profile_result_wrap {
  height: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #00BF8E;
  font-weight: bold;
  color: #fff;
}
.my_profile_result_wrap div {
  position: relative;
  width: 33.33333%;
  padding: 11px 0;
  text-align: center;
}
.my_profile_result_wrap div::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: 100%;
  display: inline-block;
  width: 1px;
  height: 15px;
  background-color: #fff;
}
.my_profile_result_wrap div:last-of-type::after {
  display: none;
}

.my_profile_lists {
  padding: 20px 15px;
  background-color: #F5F7F9;
  white-space: nowrap;
}
.my_profile_lists .double_wrap div {
  padding: 20px 24px;
  border: 1px solid #E5E5E5;
  border-radius: 7px;
  text-align: center;
  background-color: #fff;
}
.my_profile_lists .double_wrap div img {
  display: block;
  max-width: 24px;
  margin: 0 auto 6px;
}

.my_page_menus .my_page_menu {
  padding: 20px 30px;
  border-bottom: 1px solid #E1E3E7;
  font-weight: bold;
  background: url(@assets/img/arrow_right_gray_thick.png) no-repeat calc(100% - 30px) center/8px;
}
.my_page_menus .my_page_menu a {
  display: block;
  width: 100%;
  height: 100%;
}

.mypage_edit_flex {
  display: flex;
  justify-content: space-between;
}
.mypage_edit_flex a {
  font-size: 13px;
  color: #757575;
}

#edit_account .input_warn {
  display: none;
}

.edit_login_kakao {
  display: block;
  width: calc(100% - 30px);
  margin: 0 auto 25px;
  height: 50px;
  line-height: 50px;
  border-radius: 10px;
  background: url(@assets/img/icon_kakao.svg) no-repeat 15px center/18px #FEE500;
  font-weight: bold;
  text-align: center;
}


/* 나의 미션 */
.my_missoin_cate_wrap {
  display: flex;
  justify-content: space-between;
  color: #757575;
}

.my_mission_progress_wrap {
  margin-bottom: 15px;
}
.my_mission_progress_wrap .mission_progress_fill {
  background-image: linear-gradient(90deg, #00BF8E, #4A7EFE);
}

.mission_contents_wrap p {
  margin-bottom: 15px;
}
.mission_contents_wrap dl {
  padding: 10px;
  border: 1px solid #E1E3E7;
  border-radius: 10px;
}

.my_mission_btn_wrap {
  display: flex;
  justify-content: space-between;
}
.my_mission_btn_wrap .my_mission_links {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

/* 관광지 */
.courses_wrap {
  width: 100%;
}
.courses_wrap .courses_title {
  padding: 0 15px;
  font-weight: bold;
  color: #00BF8E;
}
.courses_wrap .courses_title img {
  max-height: 23px;
  margin-right: 10px;
}
.courses_wrap .courses_item {
  width: 100%;
  padding: 15px 20px;
  border-bottom: 1px solid #E1E3E7;
}
.courses_wrap .courses_item h3 {
  margin-bottom: 20px;
}
.courses_wrap .courses_item .img_wrap {
  display: block;
  width: 100%;
  height: 200px;
  margin-bottom: 15px;
}
.courses_wrap .courses_item .img_wrap img {
  width: 100%;
  max-width: 477px;
  height: 100%;
  border: 1px solid #E1E3E7;
  border-radius: 10px;
  overflow: hidden;
}
.audio_wrap {
  display: block;
  width: 100%;
  height: 35px;
  margin: 0;
  padding: 0;
}
.audio_wrap audio {width: 100%;height: 100%;border-radius: 20px;}

/* 경품 */
.choose_rewards_wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;
  column-gap: 15px;
  word-break: keep-all;
  text-align: center;
}
.choose_rewards_wrap label img {
  display: block;
  max-width: 95px;
  min-height: 95px;
  margin: 0 auto 10px;
  border: 1px solid #E1E3E7;
  border-radius: 10px;
}
.choose_rewards_wrap label strong {
  display: block;
  min-height: 50px;
  padding-bottom: 10px;
  color: #757575;
}

.rewards_info_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
}
.rewards_info_flex input {
  margin-right: 5px;
}
.rewards_info_flex div {
  display: flex;
}

.my_mission_reward {
  max-width: 33.3333%;
  margin-bottom: 20px;
}
.my_mission_reward img {
  display: block;
}
.my_mission_reward strong {
  font-size: 11px;
  color: #757575;
}

.my_rewards_info_wrap .my_rewards_info {
  padding-bottom: 15px;
  margin-bottom: 14px;
  border-bottom: 1px solid #E1E3E7;
}
.my_rewards_info_wrap .my_rewards_info strong {
  display: block;
  padding-bottom: 25px;
}

/* 약관 */
.terms_tab_wrap {
  width: 100%;
  max-width: 1000px;
  padding: 20px 15px 16px;
  border-bottom: 1px solid #E1E3E7;
  white-space: nowrap;
  overflow-x: auto;
  font-weight: bold;
}
.terms_tab_wrap label {
  padding: 0 7.5px;
  color: #ccc;
}
.terms_tab_wrap label.active, .terms_tab_wrap label:hover {
  color: #00BF8E;
}
.terms_tab_wrap::-webkit-scrollbar {
  width: 100%;
  height: 1px;
  background-color: transparent;
}
.terms_tab_wrap::-webkit-scrollbar-thumb {
  width: 20%;
  height: 1px;
  background-color: transparent;
}

.term_container input {
  display: none;
}
.term_container .term_content {
  display: none;
}
.term_container input:checked + .term_content {
  display: block;
}

/* 공지사항 & 이벤트 */
.notice_wrap ul li {
  width: 100%;
  height: 100%;
  border-top: 1px solid #E1E3E7;
}
.notice_wrap ul li.active {
  background-color: #F5F7F9;
}
.notice_wrap ul li.active .notice_pin {
  display: inline-block;
  padding: 3px 10px;
  margin-right: 10px;
  border: 1px solid #FA4163;
  border-radius: 5px;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  background-color: #fff;
  color: #FA4163;
}
.notice_wrap ul li a {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 20px 15px;
  align-items: center;
}
.notice_wrap ul li a .notice_title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notice_wrap ul li:last-of-type {
  border-bottom: 1px solid #E1E3E7;
}

.notice_view_top {
  padding: 0 15px 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E1E3E7;
}
.notice_view_top h2 {
  margin-bottom: 10px;
}

.event_wrap ul li {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E1E3E7;
}
.event_wrap ul li .event_item__img {
  width: 100%;
  max-height: 400px;
  margin-bottom: 15px;
  border-radius: 7px;
  overflow: hidden;
}
.event_wrap ul li .event_item__img img {
  width: 100%;
  height: 100%;
}
.event_wrap ul li .event_item__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 13px;
}
.event_wrap ul li .event_item__date {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.event_wrap ul li .event_item__date h2 {
  color: #00BF8E;
}

/* ======= 서브 끝 ======= */
/* ======= 푸터 ======= */
#footer {
  padding: 15px 15px 100px;
  background-color: #F5F7F9;
}
#footer span.bar {
  display: inline-block;
  width: 1px;
  height: 12px;
  margin: 0 10px;
  background-color: #757575;
  vertical-align: middle;
}
#footer small {
  line-height: 1.6;
  color: #757575;
}

/* ======= 푸터 끝 ======= */

.bg_gray{
  background-color: #ccc;
}

#lottie_container { margin: 0 auto; }

.backdrop{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #fff;
  /* opacity: 0.5; */

  display: flex;           /* establish flex container */
  flex-direction: column;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;     /* center items horizontally, in this case */
}

/* no-data */
.no_data {
  padding: 20px 0;
  text-align: center;
}

/* text-center */
.text-center {
  text-align: center;
}

/* fit width to parent */
.fit-width {
  width: 100%;
}