@charset "utf-8";

/* ======= DOCUMENT ======= */
* {box-sizing: border-box; outline: none;}

*:active, *:hover, *:focus {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html,
body {
  width: 100%;
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  
}

html {
  touch-action: manipulation;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  touch-action: manipulation;
}

a, button, .myelements
{
	-ms-touch-action: manipulation;	/* IE10  */
	touch-action: manipulation;		/* IE11+ */
  outline: none;
}

button,
select {
  text-transform: none;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  overflow: hidden;
  background-color: unset;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}


/* ======= DOCUMENT 끝 ======= */
/* ======= ELEMENTS ======= */
main {display: block;}
ul {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
img {
  max-width: 100%;
  border-style: none;
  vertical-align: middle;
}
a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}
p,
h1,
h2,
h3,
h4 {
  padding: 0;
  margin: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button {cursor: pointer;padding:0;}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size:100%;
  margin:0;
  color:inherit;
}
button,
select {text-transform: none;}
fieldset {padding: 0.35em 0.75em 0.625em;}
legend {
  padding: 0;
  color: inherit;
}
textarea {overflow: auto;}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
/* ======= ELEMENTS 끝 ======= */
/* ======= COMMON CLASS ======= */
.hide {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  line-height: 0;
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 0;
  text-indent: -9999px;
  text-shadow: none;
  background-color: transparent;
  color: transparent;
}
.overlay {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  z-index: 999;
}
.overlay.on {display: block;}

/* margin */
.mb10 {margin-bottom: 10px;}
.mb15 {margin-bottom: 15px;}
.mb20 {margin-bottom: 20px;}
.mb30 {margin-bottom: 30px;}
.mb40 {margin-bottom: 40px;}
.mb60 {margin-bottom: 60px;}
.mb80 {margin-bottom: 80px;}
.mr5 {margin-right: 5px;}
.mr10 {margin-right: 10px;}
.mr15 {margin-right: 15px;}
.mr20 {margin-right: 20px;}
/* ======= COMMON CLASS 끝 ======= */

/* ======= Burger Menu ======= */
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 21px;
  height: 40px;
  right: 15px;
  transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
}
.bm-burger-button > span span.bm-burger-bars:nth-of-type(2) {width: 16px !important;}

.bm-menu-wrap {
  left: 50%;
  transform: translateX(-50%);
}
/* Individual item */
.bm-item {
  background: url(@assets/img/arrow_next_gray_thin.png) no-repeat calc(100% - 15px) center/10px #fff;
}
.bm-item img {max-width: 24px;}
.bm-item > span {
  display: inline-block;
  width: 1px;
  height: 25px;
  margin: 0 15px;
  background-color: #ccc;
  vertical-align: middle;
}
.bm-cross-button::after {
  content:"";
  position: absolute;
  width: 25px;
  height: 25px;
  top: -8px;
  right: -10px;
  display: inline-block;
  background-color:#fff;
  z-index: -1;
}
/* ======= Burger Menu 끝 ======= */