@import "mixin";

/* ======= GLOBAL ======= */
/* color */
$defaultBlue: #4A7EFE;
$defaultGreen: #00BF8E;
$defaultRed: #FA4163;
$defaultLgray: #E1E3E7;
$defaultDgray: #757575;
$defaultBg: #F1F1F2;
$defaultBg2: #F5F7F9;

/* font */
$defaultTitle: 20px;
$defaultLgBtn: 17px;
$defaultSubject: 15px;
$defaultBody: 14px;
$defaultSm: 13px;
$defaultWarn: 11px;

/* radius */
$defaultRadius: 10px;
/* ======= GLOBAL 끝 ======= */

/* ======= COMMON ======= */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap');

*::placeholder {color: #ccc;}
body, html {
  min-width: 350px;
  max-width: 1000px;
  margin: 0 auto;
  font-size: $defaultBody;
  font-family: 'Noto Sans KR', sans-serif;
}
fieldset {
  padding: 0;
  border:0;
}
input,
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border:0;
}
select {color: #000 !important;
  option {color: #000 !important;}
}
dl, dt, dd {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
button {
  border:0;
  background-color: transparent;
}
h2 {font-size: $defaultTitle;}
h3 {font-size: $defaultLgBtn;}
h4 {font-size: $defaultSubject;}
small {font-size: $defaultSm;}

/* 인풋 */
.chk_default { 
  width: 22px;
  height: 22px;
  border-radius: 50%;
  vertical-align: middle;
  background: url(@assets/img/img_check_not.png) no-repeat center/contain; 
  &:checked {background: url(@assets/img/img_check_green.png) no-repeat center/contain;}
}
.chk_circle {
 width: 20px;
 height: 20px;
 border-radius: $defaultRadius; 
 background: url(@assets/img/img_check_line_not.png) no-repeat center/contain;
 &:checked {background: url(@assets/img/img_check_line.png) no-repeat center/contain;}
}
.input_default {
  border:1px solid $defaultLgray;
  border-radius: $defaultRadius;
  &:disabled {background-color: $defaultBg2;}
}
.select_default {
  border:1px solid $defaultLgray;
  border-radius: $defaultRadius;
  background:url(@assets/img/arrow_down_gray_thin.png) no-repeat calc(100% - 15px) center/12px #fff;
  &:disabled {background-color: $defaultBg2;}
  &:focus {background: url(@assets/img/arrow_up_gray_thin.png) no-repeat calc(100% - 15px) center/12px}
}
.input_countdown {position: relative;}
.input_warn {
  padding-top: 10px;
  color: $defaultRed;
  font-size: $defaultWarn;
  img {
    width: 18px;
    height: 18px;
  }
}
.input_value {
  padding: 0 !important;
  height: auto !important;
  border:0;
  font-weight: bold;
}
.input_address_wrap {
  margin-bottom: 15px;
  label {
    display: block;
    margin-bottom: 10px;
  }
  input {margin-bottom: 15px;}
  .input_postcode {
    @include flexBetween;
    column-gap: 5px;
  }
  .input_postcode button {
    width: 100%;
    height: 50px;
    max-width: 80px;
    border-radius: $defaultRadius;
    background-color: $defaultDgray;
    font-weight: bold;
    color:#fff;
  }
}
/* 버튼 */
.btn_default {
  width: 100%;
  height: 50px;
  border-radius: $defaultRadius;
  background-color: $defaultGreen;
  font-weight: bold;
  color:#fff;
}
.btn_default[data-bg="gray"] {
  background-color: #ccc;
}
.btn_default_r5 {
  width: 100%;
  max-width: 96px;
  height: 30px;
  border-radius: 5px;
  background-color: $defaultGreen;
  font-weight: bold;
  color:#fff;
}
.btn_default_r5[data-bg="gray"] {
  background-color: $defaultDgray;
  color:rgba(255,255,255,.5)
}
.btn_default_r5[data-bg="blue"] {background-color: $defaultBlue;}
.btn_default_r5[data-bg="red"] {background-color: $defaultRed;}
button.btn_default_r5_line {
  width: 100%;
  max-width: 80px;
  height: 30px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid $defaultGreen;
  font-weight: bold;
  color:$defaultGreen;
  white-space: nowrap;
  text-align: center;
  background-color: #fff;
}
a.btn_default_r5_line {
  display: inline-block;
  height: 30px;
  line-height: 28px;
  padding: 0 10px;
  border-radius: 5px;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  background-color: #fff;
}
.btn_default_r5_line[data-line="gray"] {
  max-width: 100px;
  border: 1px solid $defaultDgray;
  color:$defaultDgray;
}
.btn_default_r5_line[data-line="red"] {
  border: 1px solid $defaultRed;
  color:$defaultRed;
}
.btn_default_r5_line[data-line="graysm"] {
  max-width: 70px;
  line-height: 28px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid $defaultDgray;
  font-size: $defaultWarn;
  font-weight: bold;
  text-align: center;
  color:$defaultDgray;
}
.btn_gray {
  width: 100%;
  height: 50px;
  border-radius: $defaultRadius;
  background-color: #ccc;
  font-weight: bold;
  color:#fff;
}
.btn_lg {
  width: 100%;
  height: 50px;
  border-radius: $defaultRadius;
  background-color: $defaultGreen;
  font-size: $defaultLgBtn;
  font-weight: bold;
  color:#fff;
}
.btn_lg_line {
  width: 100%;
  height: 50px;
  border-radius: $defaultRadius;
  border: 1px solid $defaultGreen;
  font-size: $defaultLgBtn;
  font-weight: bold;
  color:$defaultGreen;
}
.btn_lg_gray {
  width: 100%;
  height: 50px;
  border-radius: $defaultRadius;
  background-color: #ccc;
  font-size: $defaultLgBtn;
  font-weight: bold;
  color:#fff;
}
.btn_blue {
  width: 100%;
  height: 50px;
  border:1px solid $defaultBlue;
  border-radius: $defaultRadius;
  font-weight: bold;
  color: $defaultBlue;
}
.btn_back {
  position: absolute;
  left:15px;
  @include yCenter;
  display: inline-block;
  width: 12px;
  height: 22px;
  background: url(@assets/img/arrow_left_bl_thick.png) no-repeat center/contain;
  vertical-align: middle;
}
.btn_next {
  display: inline-block;
  width: 13px;
  height: 10px;
  background: url(@assets/img/arrow_right_bl_thick.png) no-repeat center/contain;
}
.btn_search {
  display: inline-block;
  width: 19px;
  height: 19px;
  background: url(@assets/img/icon_search_bl.png) no-repeat center/contain;
}
.btn_search_gray {
  width: 17px;
  height: 17px;
  background: url(@assets/img/icon_search_gray.png) no-repeat center/contain;
}
.btn_like {
  width: 19px;
  height: 17px;
  background: url(@assets/img/icon_heart.png) no-repeat center/contain;
}
.btn_liked {
  background: url(@assets/img/icon_heart_fill.png) no-repeat center/contain;
}
.btn_share {
  width: 18px;
  height: 18px;
  background: url(@assets/img/icon_share_bl.png) no-repeat center/contain;
}
.btn_clear {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url(@assets/img/img_clear.png) no-repeat center/contain;
  border: 0;
  vertical-align: middle;
  cursor: pointer;
}
.btn_mission_back {
  position: fixed;
  right: 15px;
  bottom: 20px;
  display: inline-block;
  width: 37px;
  height: 37px;
  background: url(@assets/img/img_m_back.png) no-repeat center/contain;
}
.btn_mission_leave {
  width: 50px;
  height: 50px;
  border-radius: $defaultRadius;
  background: url(@assets/img/img_close_wh.png) no-repeat center/11px $defaultDgray;
}
.btn_modal_close {
  width: 11px;
  height: 11px;
  background: url(@assets/img/img_close_green.png) no-repeat center/contain;
}
.btn_count_minus,
.btn_count_add {
  width: 24px;
  height: 24px;
}
.btn_count_minus {background: url(@assets/img/img_minus.png) no-repeat center/contain;}
.btn_count_add {background: url(@assets/img/img_add.png) no-repeat center/contain;}
.btn_pop_yes {
  display: inline-block;
  width: calc(50% - 10px);
  height: 50px;
  line-height: 48px;
  font-weight: bold;
  border: 1px solid $defaultGreen;
  border-radius: $defaultRadius;
  color:$defaultGreen;
}
.btn_pop_no {
  display: inline-block;
  width: calc(50% - 10px);
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  border-radius: $defaultRadius;
  background-color: $defaultGreen;
  text-align: center;
  color:#fff;
}
.divide_thick {
  display: inline-block;
  width: 100%;
  height: 8px;
  background-color: $defaultBg;
}
.colblue {color: $defaultBlue;}
.colgreen {color: $defaultGreen;}
.colred {color: $defaultRed;}
.coldg {color: $defaultDgray;}
.code_countdown {color: #ccc;}
.undline {text-decoration: underline;}
/* ======= COMMON 끝 ======= */

/* ======= 헤더 & 하단 ======= */
/* 헤더 */
.header_wrap {
  position: relative;
  #header {
    width: 100%;
    height: 80px;
    padding: 0 15px;
    @include flexBetween;
    align-items: center;
    z-index: 99;
    .logo img {
      width: 100%;
      max-width: 106px;
    }
    .header_right {
      @include flex;
      column-gap: 17px;
      align-items: center;
      .btn_notify {
        // position: relative;
        width: 17.5px;
        height: 19px;
        background: url(@assets/img/icon_notfiy_bl.png) no-repeat center/contain;
        // .btn_notify-count {
        //   position: absolute;
        //   top: -5px;
        //   right: -5px;
        //   width: 12px;
        //   min-width: 12px;
        //   height: 12px;
        //   line-height: 12px;
        //   border-radius: 50%;
        //   background-color: $defaultGreen;
        //   font-size: .7em;
        //   color:#fff;
        // }
      }
      .btn_gnb {
        position: relative;
        width: 21px;
        height: 40px;
        // height: 20px;
        transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
        user-select: none;
        span {
          position: absolute;
          left: 0;
          display: inline-block;
          width: 21px;
          height: 2px;
          background-color: #000;
        }
        span:nth-of-type(1) {top: 12.5px;}
        span:nth-of-type(2) {top:19px; width: calc(21px * 0.6);}
        span:nth-of-type(3) {top: 25.5px;}
        &:hover, &.active {
          transform: rotate(-90deg);
          span:nth-of-type(1) {transform: rotate(45deg) translate(4.5px,4.5px);}
          span:nth-of-type(2) {left:-5%;width:0;opacity: 0;}
          span:nth-of-type(3) {transform: rotate(-45deg) translate(4.5px,-4.5px);}
        }
      }
    }
  }
  .header_nav_wrap {
    position: absolute;
    top: -400%;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $defaultGreen;
    .header_nav {padding: 15px;}
    .header_nav ul li {
      width: 100%;
      height: 60px;
      line-height: 60px;
      padding: 0 15px;
      border-radius: $defaultRadius;
      background:url(@assets/img/arrow_next_gray_thin.png) no-repeat calc(100% - 15px) center/10px #fff;
      a {display: block;}
      img {max-width: 24px;}
      span {
        display: inline-block;
        width: 1px;
        height: 25px;
        margin: 0 15px;
        background-color: #ccc;
        vertical-align: middle;
      }
    }
    &.active {height: 300px;top: 80px;z-index:99;}
  }
}
.header_search_wrap {
  position: relative;
  border-bottom: 1px solid $defaultLgray;
  .search_right {
    @include flex;
    width: 100%;
    padding-left: 27px;
    column-gap: 10px;
    input {width: 90%;}
  }
  .btn_search z{
    display: inline-block;
    width: 19px;
    height: 19px;
    background: url(@assets/img/icon_search_bl.png) no-repeat center/contain;
  }
}
.login_back_wrap {
  position: relative;
  height: 80px;
  line-height: 80px;
  // height: 64px;
  // line-height: 64px;
}
/* 하단 */
.bottom_menus {
  position: fixed;
  bottom: 0;
  @include xCenter;
  @include flexBetween;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  height: 74px;
  padding: 10px;
  box-shadow: 0 -2px 7px #0000001A;
  background-color: #fff;
  div:nth-of-type(1) .bottom_menu__img{background-image: url(@assets/img/tab_home.svg);}
  div:nth-of-type(2) .bottom_menu__img{background-image: url(@assets/img/tab_rank.svg);}
  div:nth-of-type(3) .bottom_menu__img{background-image: url(@assets/img/tab_order.svg);}
  div:nth-of-type(4) .bottom_menu__img{background-image: url(@assets/img/tab_play.svg);}
  div:nth-of-type(5) .bottom_menu__img{background-image: url(@assets/img/tab_my.svg);}
  div:nth-of-type(1):hover .bottom_menu__img,
  div:nth-of-type(1) > .active .bottom_menu__img{background-image: url(@assets/img/tab_home_green.svg);}
  div:nth-of-type(2):hover .bottom_menu__img,
  div:nth-of-type(2) > .active .bottom_menu__img{background-image: url(@assets/img/tab_rank_green.svg);}
  div:nth-of-type(3):hover .bottom_menu__img,
  div:nth-of-type(3) > .active .bottom_menu__img{background-image: url(@assets/img/tab_order_green.svg);}
  div:nth-of-type(4):hover .bottom_menu__img,
  div:nth-of-type(4) > .active .bottom_menu__img{background-image: url(@assets/img/tab_play_green.svg);}
  div:nth-of-type(5):hover .bottom_menu__img,
  div:nth-of-type(5) > .active .bottom_menu__img{background-image: url(@assets/img/tab_my_green.svg);}
  .bottom_menu {
    width: 20%;
    text-align: center;
    font-size: 12px;
    color:$defaultDgray;
    &:hover,
    .active {color: $defaultGreen;}
    .bottom_menu__img {
      display: inline-block;
      width: 100%;
      height: 100%;
      max-width: 24px;
      max-height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
.register_bottom {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 15px);
  max-width: 1000px;
  @include xCenter;
  
  small {
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
      color: $defaultDgray;
  }
  .btn_lg_gray:hover {
    background-color: $defaultGreen;
    color:#fff;
  }
}
.register_done_bottom {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 15px);
  max-width: 1000px;
  @include xCenter;
}
.mission_bottom {
  position: fixed;
  bottom: 0;
  @include xCenter;
  @include flexBetween;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  padding: 15px;
  column-gap: 10px;
  background-color: $defaultBg;
  .btn_lg {
    display: inline-block;
    width: calc(50% - 5px);
    line-height: 50px;
    text-align: center
  }
  .mission_pay {background-color: $defaultRed;}
  .mission_ing {background-color: $defaultBlue;}
}
.pay_bottom,
.rewards_bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 15px 30px;
}
.pay_bottom,
.start_bottom,
.rewards_bottom {
  .btn_lg {
    display: inline-block;
    width: 100%;
    line-height: 50px;
    text-align: center
  }
  .mission_enter {
    &.disabled {
      background-color: $defaultDgray;
      color: rgba(255,255,255,.5);
    }
  }
}
.play_bottom {
  position: fixed;
  bottom: 0;
  @include xCenter;
  @include flex;
  width: 100%;
  max-width: 1000px;
  padding: 20px 15px 30px;
  row-gap: 15px;
  flex-direction: column;
  &::before {
    content:"";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom,transparent,40%, rgba(255,255,255,.7));
    z-index: -1;
  }
  & > div {
    @include flexBetween;
    flex-wrap: wrap;
  }
  .hint_count {
    display: inline-block;
    width: auto;
    height: 100%;
    padding: 5px 10px;
    border-radius: 25px;
    background-color: $defaultBlue;
    text-align: center;
    color:#fff;
  }
  .mission_submit {
    width: calc(100% - 60px);
    line-height: 50px;
    text-align: center;
  }
}
/* ======= 헤더 & 탭 끝 ======= */

/* ======= 모달 ======= */
.modal-dialog {
  max-width: 80%;
  margin: 0 auto;
}
.modal-content {
  width: 100%;
  border-radius: $defaultRadius;
}
.modal-body {
  position: relative;
  min-height: 160px;
  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    @include Center;
  }
  .modal_text_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    justify-content: center;
    text-align: center;
    word-break: keep-all;
  }
    small {color:$defaultDgray;}
}
.modal-footer {@include flexBetween;}
.modal-term {
  height: 100%;
  max-height: 60vh;
  padding: 20px 15px 20px;
  overflow-y: auto;
  small {display: block;}
}
#detail_share {
  .modal-body > div {padding: 15px;}
  .detail_share_header {
    @include flexBetween;
  }
  .detail_share_links {
    @include flexBetween;
    padding: 20px 18px;
    align-items: center;
    text-align: center;
    img {
      display: block;
      max-width: 45px;
      max-height: 45px;
      margin-bottom: 8px;
    }
    small {color: $defaultDgray};
  }
}
#mission_img_wide {
  .btn_modal_close {
    position: fixed;
    top: 50px;
    right: 15px;
    background: url(@assets/img/img_close_wh.png) no-repeat center/contain;
  }
  .modal-dialog {max-width: 100%;}
  .modal-content {
    border:0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    img {
      width: 100%;
      max-height: 50vh;
    }
  }
}
#mission_success {
  .modal-backdrop {
    &::after {
      content:"";
      position: fixed;
      bottom: 20vh;
      @include xCenter;
      display: inline-block;
      width: 100px;
      height: 100px;
      background: url(@assets/img/img_success.png) no-repeat center/contain;
      filter: hue-rotate(12deg) saturate(5);
    }
  }
}
#mission_winners {
  .modal-body {
    max-height: 60vh;
    padding: 15px;
    overflow-y: auto;
  }
  .pop_winners_wrap li {
  @include flex;
    padding: 17px 3px;
    column-gap: 15px;
    border-bottom: 1px solid $defaultLgray;
    align-items: center;
  
    .rank_list__no {
      color: $defaultGreen;
    }
  
    .rank_list__img img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
    }
  
    .rank_list__text {
      strong {
        display: block;
        padding-bottom: 5px;
      }
    }
}
}
/* ======= 모달 끝 ======= */

/* ======= 바디 ======= */
.container {
  width:100%;
  .container_inner {
    padding:0 15px;
  }
  .container_fit {
    padding-bottom: calc(84px + 15px);
  }
  
  .input_default {
    width: 100%;
    height: 50px;
    padding: 0 15px;
  }
  .input_default:hover, .input_default:focus{border: 1px solid $defaultGreen;}
  .input_clear {
    position: relative;
    .btn_clear {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: url(@assets/img/img_clear.png) no-repeat center/contain;
      border: 0;
      cursor: pointer;
    }
  }
  .select_default {
    width: 100%;
    height: 50px;
    padding: 0 15px;
  }
}
.login_full_container  {
  & {
    background-color: $defaultBlue;
    color: #fff;
  }
  .container {
    position: relative;
    height: 100vh;
    padding: 100px 15px 0 15px;
    
    .login_top {
    @include flexBetween;
    align-items: center;

      img {
        max-width: 95px;
      }
    }
      .login_methods {
        margin-bottom: 40px;
      font-weight: bold;
      text-align: center;
      li {
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-bottom: 15px;
        background-color: #fff;
        border-radius: $defaultRadius;
        background-position: 15px center;
        background-size: 20px;
        background-repeat: no-repeat;
        color:#000;
      }
      li:nth-of-type(1) {
        background-image: url(@assets/img/icon_kakao.svg);
      }
      li:nth-of-type(2) {
        background-image: url(@assets/img/icon_naver.svg);
        color:#03C75A;
      }
      li:nth-of-type(3) {
        background-image: url(@assets/img/icon_fb.svg);
        color:#1877F2;
      }
      li:nth-of-type(4) {
        background-image: url(@assets/img/icon_apple.svg);
      }
      li:nth-of-type(5) {
        color: $defaultRed;
      }
    }
      .login_bottom {
        // position: absolute;
        // bottom: 20px;
        // width: calc(100% - 15px);
        // @include xCenter;
      .undline {
        display: block;
        margin-bottom: 18px;
        font-weight: bold;
        text-align: center;
      }
      small {
        display: inline-block;
        width: 100%;
        text-align: center;
      }
    }
  }
}
.paid_container {
  position: absolute;
  top: 35%;
  width: 100%;
  text-align: center;
  img {
    max-width: 50px;
    max-height: 50px;
    margin-bottom: 20px;
  }
  h2 {margin-bottom: 30px;}
  .btn_lg {margin-bottom: 10px;}
  .btn_lg,
  .btn_lg_line {
    display: inline-block;
    line-height: 50px;
    text-align: center;
  }
}
.mission_team_item_container {
  max-height:60vh;
  padding-bottom:100px;
  overflow-y:scroll;
}
.double_wrap {
  @include flexBetween;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  div {
    width: calc(50% - 7.5px);
  }
}
.triple_wrap {
  @include flexBetween;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  div {
    width: calc(33.33333% - 5px);
  }
}
.quarter_wrap {
  @include flexBetween;
  width: 100%;
  row-gap:10px;
  flex-wrap: wrap;
  align-items:center;
  div {
    width: calc(50% - 5px);
  }
}
.toggle_sibling {
  width: 100%;
  margin-bottom: 15px;
  background: url(@assets/img/arrow_up_gray_thin.png) no-repeat calc(100% - 15px) center/11px;
  text-align: left;
  &.active {background: url(@assets/img/arrow_down_gray_thin.png) no-repeat calc(100% - 15px) center/11px;}
}
.toggle_load {
  width: 100%;
  padding-right: 1.5em;
  background: url(@assets/img/arrow_down_gray_thin.png) no-repeat right center/11px;
  text-align: center;
  &.active {background: url(@assets/img/arrow_up_gray_thin.png) no-repeat right center/11px;}
}
/* ======= 바디 끝 ======= */

/* ======= 서브 ======= */
/* 로그인 및 가입*/
#login_email {
  margin-bottom: 20px;
}
.login_email_bottom {
  @include flexBetween;
  align-items: center;
  font-size: $defaultSm;
  color: $defaultDgray;
}
.login_find_tab {
  @include flexBetween;
  width: 100%;
  margin-bottom: 60px;
  h2 {margin-bottom: 60px;}
  a {
    display: inline-block;
    width: 50%;
    padding-bottom: 15px;
    border-bottom: 2px solid $defaultLgray;
    text-align: center
  }
  a.on {
    border-bottom: 2px solid $defaultBlue;
    font-weight: bold;
    color:$defaultBlue;
  }
}
.login_find_id {
  h2 {padding-bottom: 60px;}
  fieldset > div {
    @include flexBetween;
    column-gap: 5px;
  }
  fieldset > div button {max-width: 80px;}
  .code_countdown {
    position: absolute;
    left: calc(100% - 135px);
    @include yCenter;
  }
  #resend_code {color: $defaultDgray;}
  #resend_code img {
    width: 8px;
    margin-top: -2px;
    margin-left: 5px;
  }
}
.login_find_id_result_wrap {
  p {
    padding: 100px 0;
    text-align: center;
  }
  p span {color: $defaultRed;}
}
.term_chkall_wrap {
  width: 100%;
  height: 50px;
  padding: 14px 15px;
  margin-bottom: 15px;
  background-color: $defaultBg2;
  border-radius: $defaultRadius;
  font-weight: bold;
}
.term_chk_wrap {
  position: relative;
  width: 100%;
  padding: 14px 15px;

  .pop_term {
    position: absolute;
    right: 15px;
    @include yCenter;
    width: 12px;
    height: 16px;
    background: url(@assets/img/arrow_right_gray_thin.png) no-repeat center/contain;
  }
}
#register_account,
#edit_account,
#delete_account,
#mission_rewards {
  fieldset:not(.register_account_optional) label {
    display: block;
    padding-bottom: 10px;
    font-size: $defaultSm;
  }
  fieldset:not(.register_account_optional) input,
  fieldset:not(.register_account_optional) select {
    margin-bottom: 15px;
  }
  fieldset .input_clear {margin-bottom: 15px;}
  fieldset .input_clear input {margin-bottom: 0;}
  .register_account_optional_bg {
    padding: 40px 0 25px;
    background-color: $defaultBg2;
    .register_account_optional select {margin-bottom: 15px;}
    .double_wrap {
      margin-bottom: 20px;
      font-size: $defaultSm;
      div{
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        border: 1px solid $defaultLgray;
        border-radius: $defaultRadius;
        background-color: #fff;
        color: $defaultDgray;
        input {margin-right: 10px;}
        @include mediaQuery(sm) {
          width: calc(50% - 2.5px);
          padding: 0 10px;
          input {margin-right: 5px;}
        }
      }
    }
  }
  small {
    display: block;
    margin-bottom: 20px;
    color:$defaultDgray;
  }
  textarea {
    width: 100%;
    height: 100%;
    padding: 15px;
    border: 1px solid $defaultLgray;
    border-radius: $defaultRadius;
    resize: vertical;
  }
}
.register_done_contents {
  position: absolute;
  top: 35%;
  @include xCenter;
  width: 100%;
  text-align: center;
  img {margin-bottom: 25px;}
  .register_done_text {margin-bottom: 20px;}
}

/* 메인 화면 */
.index_search_wrap {
  position: relative;
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border-radius: $defaultRadius;
  background-color: $defaultBg2;
  input {
    width: calc(100% - 35px);
    height: 100%;
    background-color: transparent;
  }
  .btn_search_gray {
    position: absolute;
    right: 15px;
    @include yCenter;
  }
}
.index_welcome_note {
  position: relative;
  height: 100%;
  padding-top: 50px;
  margin-bottom: 50px;
  @include flexBetween;
  .index_welcome_note__right {
    position: relative;
    top: -10px;
    &::before {
      content:"";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), 80%, rgba(255,255,255,1));
    }
    img {
      width: 100%;
      max-width: 120px;
    }
  }
  // & > img {
  //   position: absolute;
  //   right: 0;
  //   @include yCenter;
  //   max-width: 120px;
  //   z-index: 1;
  // }
  h2 {
    margin-bottom: 15px;
  }
  a {
    color: $defaultDgray;
    img {
      max-width: 8px;
      margin-left: 10px;
    }
  }
}
.index_tip_slider_wrap {
  margin-bottom: 40px;
  border-radius: $defaultRadius;
  overflow: hidden;
  .index_tip_slider {
    width: 100%;
    height: 82px;
    border-radius: 10px;
    overflow: hidden;
    .slick-slide {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: $defaultRadius;
      overflow: hidden;
      a {
        display: block;
        width: 100%;
        height: 100%;
        // max-height: 82px;
        // padding: 15px;
        // border-radius: $defaultRadius;
        // overflow: hidden;
        img {
          width: 100%;
          height: 82px;
        }
      }
    }
    // div:nth-of-type(1) {
    //   small {
    //     display: inline-block;
    //     padding-bottom: 6px;
    //   }
    //   background-image: linear-gradient(180deg, #47C7A6, $defaultGreen );
    //   color:#fff;
    // }
    .slick-dots {
      position: absolute;
      max-width: 50px;
      height: 26px;
      // line-height: 32px;
      // padding: 5px 0;
      top: 15px;
      right: 15px;
      border-radius: 50px;
      text-align: center;
      font-size: $defaultWarn;
      background-color: rgba(0,0,0,.3);
      color:#fff;
      li {display: none;}
      li.slick-active {
        position: relative;
        display: block;
        height: 100%;
        span {
          position: absolute;
          @include Center;
          top: 48%;
          display: inline-block;
          width: 100%;
        }
      }
    }
    
  }
}
.index_quarter_wrap {
  margin-bottom: 40px;
  label {
    width: calc(50% - 7.5px);
    height: 50px;
    line-height: 50px;
    color:$defaultDgray;
    border:1px solid $defaultLgray;
    border-radius: $defaultRadius;
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size:24px;
    background-color: $defaultBg2;
    &:hover,
    &.active {background-color: #fff;}
    strong {
      display: block;
      width: 100%;
      height: 100%;
      padding-left: calc(39px + 10px);
    }
    
  }
  .index_solo {
      background-image:url(@assets/img/icon_h1.png);
      &:hover, 
      &.active {
        border:1px solid $defaultGreen;
        background-image: url(@assets/img/icon_h1_green.png);
        color:$defaultGreen;
      }
    }
    .index_team {
      background-image:url(@assets/img/icon_h2.png);
      &:hover, 
      &.active {
        border:1px solid $defaultBlue;
        background-image: url(@assets/img/icon_h2_blue.png);
        color:$defaultBlue;
      }
    }
    .index_stamp {
      background-image:url(@assets/img/icon_h3.png);
      &:hover, 
      &.active {
        border:1px solid $defaultRed;
        background-image: url(@assets/img/icon_h3_red.png);
        color:$defaultRed;
      }
    }
    .index_spots {
      background-image:url(@assets/img/icon_h4.png);
      &:hover, 
      &.active {
        border:1px solid #FF9400;
        background-image: url(@assets/img/icon_h4_yellow.png);
        color:#FF9400;
      }
    }
}
.index_quarter_contents {
  input {display: none;}
  .index_mission_wrap {display: none;}
  input:checked + .index_mission_wrap {display: block;}
}
.index_mission_wrap {
  width: 100%;
  height: 100%;
}
.mission_items {
  @include flex;
  flex-direction: column;
  .mission_item:last-of-type {border-bottom: 0;}
  .mission_item {
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid $defaultLgray;
    .img_wrap {
      display: block;
      width: 100%;
      height: 200px;
      margin-bottom: 15px;
      img {
        width: 100%;
        max-width: 477px;
        height: 100%;
        border:1px solid $defaultLgray;
        border-radius: $defaultRadius;
        overflow: hidden;
      }
    }
    .mission_period_wrap {
      @include flexBetween;
      margin-bottom: 10px;
      align-items: center;
      color: $defaultDgray;
      strong {margin-right: 10px;}
    }
    .mission_title {
      margin-bottom: 18px;
      @include ellipsis(2)
    }
    .spot_title {
      margin-bottom: 10px;
      @include ellipsis(2)
    }
    .mission_info {
      @include flexBetween;
      align-items: center;
      small {
        color: $defaultDgray;
        img {
          max-width: 18px;
          margin-right: 5px;
        }
      }
      .mission_price {
        h3 {
          display: inline-block;
          color: $defaultGreen;
        }
      }
      .mission_info_winner {
        a {
          display: inline-block;
          width: auto;
          padding: 3px 10px;
          border: 1px solid $defaultGreen;
          border-radius: 5px;
          font-weight: bold;
          text-align: center;
          color: $defaultGreen;
        }
      }
    }
  }
}
.load_more_wrap {
  width: 100px;
  margin: 0 auto;
  padding-bottom: 30px;
}

/* 검색 */
.search_history_wrap {
  padding: 20px 15px 15px;
  .contents_subject {
    padding-bottom: 10px;
    font-weight: bold;
    color: $defaultBlue;
  }
  .search_keywords {
    @include flex;
    align-items: center;
    column-gap: 10px;
    .search_keyword {
      a {
        padding-right: 5px;
        font-weight: bold;
      }
    }
  }
}
.search_cate_wrap {
  width: 100%;
  max-width: 1000px;
  padding: 20px 15px 16px;
  border-bottom: 1px solid $defaultLgray;
  white-space: nowrap;
  overflow-x: auto;
  font-weight: bold;
  a {
    padding: 0 7.5px;
    color:#ccc;
    &.active,&:hover {color: $defaultGreen;}
  }
  &::-webkit-scrollbar {
    width: 100%;
    height: 1px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 20%;
    height: 1px;
    background-color: transparent;
  }
}
.search_result_wrap {
  width: 100%;
  .container_inner {
    height: calc(60vh - 100px);
    & > small {
      display: none;
      // @include flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      color: $defaultDgray;
    }
    .search_result_info {
      @include flexBetween;
      padding: 15px 0 20px;
      #search_order {
        padding-right: 10px;
        background: url(@assets/img/arrow_down_gray_thin.png) no-repeat right center/11px;
        text-align: center;
        &:hover, &:active {background: url(@assets/img/arrow_up_gray_thin.png) no-repeat right center/11px;}
      }
    }
  }
}

/* 미션 상세 */
.detail_countdown_wrap {
  @include flexBetween;
  align-items: center;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: $defaultRadius;
  background-color: $defaultGreen;
  color:#fff;
  .detail_count_down_left {white-space: nowrap;}
  .detail_count_down_left img {
    max-width: 18px;
    max-height: 18px;
    margin-right: 10px;
    @include mediaQuery(sm) {
      margin-right: 2px;
    }
  }
  .detail_countdown_right {opacity: .7;}
}
.detail_cate {
  margin-bottom: 11px;
  color: $defaultGreen;
  img {
    max-height: 20px;
    margin-right: 8px;
  }
  &[data-name="team"]{color:$defaultBlue;}
  &[data-name="stamp"]{color:$defaultRed;}
  &[data-name="spots"]{color:#FF9400;}
}
.detail_title {margin-bottom: 15px;}
.detail_mission_info {
  @include flex;
  margin-bottom: 15px;
  line-height: 2.5;
  font-size: $defaultSm;
  flex-wrap: wrap;
  dt {
    width: 90px;
    font-weight: bold;
    color: $defaultDgray;
  }
  dd {
    width: calc(100% - 95px);
    &:last-of-type {
      @include flexBetween;
      align-items: center;
    }
  }
}
.detail_result {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: $defaultRadius;
  border: 1px solid $defaultLgray;
  .triple_wrap {
    div {
      @include flexBetween;
      width: calc(33.33333% - 20px);
      position: relative;
      &::after {
        content:"";
        position: absolute;
        @include yCenter;
        left: calc(100% + 15px);
        display: inline-block;
        width: 1px;
        height: 13px;
        background-color: $defaultLgray;
      }
      &:last-of-type::after {display: none;}
    }
  }
}
.detail_img_slider_wrap {
  margin-bottom: 30px;
  .detail_img_slider {
    width: 100%;
    // height: 190px;
    height: 100%;
    .slick-slide {
      border-radius: $defaultRadius;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        max-height: 190px;
        object-fit: cover;
      }
    }
    .slick-dots {
      position: absolute;
      max-width: 50px;
      // padding: 5px 0;
      height: 26px;
      // line-height: 32px;
      bottom: 19px;
      right: 16px;
      border-radius: 25px;
      text-align: center;
      font-size: $defaultSm;
      background-color: rgba(0,0,0,.3);
      color:#fff;
      li {display: none;}
      li.slick-active {
        position: relative;
        display: block;
        height: 100%;
        span {
          position: absolute;
          @include Center;
          display: inline-block;
          width: 100%;
        }
      }
    }
  }
}
.detail_rewards_slider_wrap {
  margin-bottom: 20px;
  // .slick-list {padding: 0 20% 0 0 !important;}
}
.detail_mission_reward {
  padding: 0 4px;
  text-align: center;
  img {
    // max-width: 95px;
    // min-height: 95px;
    // max-width: 100%;
    width:33vw;
    height:33vw;
    margin: 0 auto 10px;
    border:1px solid $defaultLgray;
    border-radius: $defaultRadius;
  }
  span {
    display: inline-block;
    text-align: center;
    word-break: keep-all;
  }
}
.mission_contents {
  .mission_content {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $defaultLgray;
    .mission_content__title {
      position: relative;
      @include flex;
      margin-bottom: 15px;
      align-items: center;
      img {
        max-width: 36px;
        max-height: 36px;
        margin-right: 10px;
      }
      h2 {width: 75%;}
      .btn_mission_back {
        position: absolute;
        @include yCenter;
        right: 15px;
      }
    }
    .mission_content__img {
      margin-bottom: 15px;
      img {
        width: 100%;
        max-width: 477px;
      }
    }
    .mission_success_mark {display: none;}
    &.success {
      position: relative;
      *:not(.mission_success_mark) {
        filter: saturate(0) opacity(0.25);
        -webkit-filter: saturate(0) opacity(0.25);
        -ms-filter: saturate(0) opacity(0.25);
      }
      .mission_success_mark {
        position: absolute;
        @include Center;
        display: inline-block;
        width: 108px;
        height: 96px;
        // background: url(@assets/img/img_success.png) no-repeat center/contain;
        z-index: 2;
      }
    }
  }
}
.mission_start_container {
  position: relative;
  width: 100%;
  height: calc(90vh - 100px);
  text-align: center;
  .mission_start_text {
    padding-top: 10vh;
    margin-bottom: 20px;
    img {
      max-width: 50px;
      max-height: 50px;
      margin-bottom: 20px;
    }
    h2{
      width: 60%;
      margin: 0 auto 30px; 
      word-break: keep-all;
    }
    h3 {
      margin-bottom: 15px;
      color: $defaultGreen
    }
  }
  #form_mission_key input {
    &::placeholder {text-align: center;}
    margin-bottom: 15px;
  }
  .mission_start_bottom {
    padding-top: 30px;
    text-align: center;
    // position: absolute;
    // bottom: 20px;
    // width: calc(100% - 15px);
    // @include xCenter;
    small {
      display: block;
      padding-bottom: 10px;
      color:$defaultDgray
    }
    a {
      font-size: $defaultSm;
      color: $defaultBlue;
    }
  }
}
.mission_pay_notice {
  margin-bottom: 20px;
  p {
    @include flex;
    column-gap: 10px;
    .colblue {
      font-weight: bold;
      font-size: $defaultLgBtn;
    }
  }
}
.mission_pay_key_count_wrap {
  @include flexBetween;
  padding: 15px;
  margin-bottom: 20px;
  align-items: center;
  border: 1px solid $defaultLgray;
  border-radius: $defaultRadius;
  background-color: $defaultBg2;
  .mission_pay_key_count {
    @include flex;
    strong {margin: 0 20px;}
  }
}
.mission_pay_sum_wrap {
  @include flexBetween;
  line-height: 2;
  flex-wrap: wrap;
  align-items: center;
  dt {
    width: 100px;
    font-weight: bold;
  }
  dd {
    width: calc(100% - 115px);
    text-align: right;
    h2 {display: inline-block;}
  }
}


/* 미션 참여 */
.team_start_wrap {
  width: 100%;
  height: 100%;
  .team_start_text {
    @include flexBetween;
    align-items: center;
    margin-bottom: 10px;
    label {
      display: inline-block;
      border-radius: 5px;
      width: 42px;
      padding: 4px 0;
      margin-right: 5px;
      border:1px solid #ff9400;
      font-weight: bold;
      text-align: center;
      color: #ff9400;
      &[data-num="2"] {
        border:1px solid $defaultRed;
        color: $defaultRed;
      }
      &[data-num="3"] {
        border:1px solid $defaultGreen;
        color: $defaultGreen;
      }
      &[data-num="4"] {
        border:1px solid $defaultBlue;
        color: $defaultBlue;
      }
    }
    small {
      width: 80%;
      font-weight: bold;
      color:$defaultDgray;
    }
  }
  .input_clear {margin-bottom: 20px;}
  .team_party_wrap {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: $defaultRadius;
    background-color: $defaultBg2;
  }
}
.mission_lists_countdown_wrap {
  padding: 15px;
  background-color: $defaultBg2;
  border-top:1px solid $defaultLgray;
  border-bottom:1px solid $defaultLgray;
  .mission_countdown_wrap {
    margin-bottom: 15px;
  }
}
.mission_progress_wrap {
  @include flexBetween;
  align-items: center;
  .mission_progress_bar {
    position: relative;
    width: calc(100% - 50px);
    height: 10px;
    border-radius: 25px;
    background-color: $defaultLgray;
    .mission_progress_fill {
      position: absolute;
      @include yCenter;
      left: 0;
      width: 25%;
      height: 100%;
      background-color: $defaultGreen;
      border-radius: 25px;
    }
  }
}
.mission_list_contents {
  .mission_content {
    padding-top: 20px;
    p {padding-bottom: 15px;}
    .mission_content_btn_wrap {text-align: right;}
  }
}
.team_party_wrap {
  li {
    @include flex;
    margin-bottom: 15px;
    column-gap: 15px;
    align-items: center;
    .team_party__img {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
    &:last-of-type {margin-bottom: 0;}
  }
}
.mission_alert {
  display: none;
  padding: 11px 15px;
  background-color: $defaultRed;
  font-size: $defaultSm;
  color: #fff;
  &.active {
    position: sticky;
    top: 0;
    display: block;
    z-index: 2;
  }
}
.mission_item_q {
  padding-bottom: 20px;
  .mission_mission {
    display: inline-block;
    width: auto;
    height: 100%;
    padding: 3px 10px;
    margin-top: 20px;
    border: 1px solid $defaultRed;
    border-radius: 5px;
    font-weight: bold;
    font-size: $defaultSm;
    color: $defaultRed;
  }
  h2 {padding: 15px 0 10px;}
}
#mission_item_type1 {
  position: relative;

}
.mission_item_type1 {
  .item_type1__img {
    margin-bottom: 20px;
    border-radius: $defaultRadius;
    img{max-height: 110px;}
  }
}
.mission_item_type2 {
  .mission_attach {
    display: block;
    width: 100%;
    height: 50%;
    line-height: 48px;
    border: 1px solid $defaultGreen;
    border-radius: $defaultRadius;
    text-align: center;
    color: $defaultGreen;
    img {max-width: 21px;}
  }
  input[type="file"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
    left: -9999px;
    font-size: 0;
    visibility: hidden;
  }
}
.mission_item_type3 {
  label {
    @include flexBetween;
    align-items: center;
    width: 100%;
    height: 50px;
    line-height: 48px;
    padding: 0 15px;
    margin-bottom: 10px;
    border: 1px solid $defaultLgray;
    border-radius: $defaultRadius;
    &.active {border:1px solid $defaultGreen}
  }
}
.mission_item_type4 {
  @include flexBetween;
  flex-wrap: wrap;
  input {
    width: calc(50% - 6px);
    min-height: 155px;
    border: 1px solid #E6E8EC;
    border-radius: $defaultRadius;
    
  }
  input:first-of-type {
    background: url(@assets/img/img_correct.png) no-repeat center/50px;
    &:checked {border:1px solid $defaultGreen}
  }
  input:last-of-type {
    background: url(@assets/img/img_wrong.png) no-repeat center/50px;
    &:checked {border:1px solid $defaultRed}
  }
}

/* 스탬프 투어 */
.mission_stamp_wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: 100%;
  width: 330px;
  height: 330px;
  margin: 20px auto 30px;
  .mission_stamp_quarter {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    // margin-top: 20px;
    border: 1px solid $defaultLgray;
    background-image: url(@assets/img/img_dummy8.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size:200%;
    &:nth-of-type(1) {background-position: left top;border-right:0;border-bottom:0;}
    &:nth-of-type(2) {background-position: right top;border-bottom: 0;}
    &:nth-of-type(3) {background-position: left bottom;border-right: 0;}
    &:nth-of-type(4) {background-position: right bottom;}
    strong {
      display: grid;
      width: 100%;
      height: 100%;
      align-items: center;
      text-align: center;
      background-color: rgba(117, 117, 117, .98);
      color:#fff;
    }
    &.active {
      strong {
        visibility: hidden;
      }
    }
  }
}

/* 구매 내역 */
.order_lists {
  h2 {padding: 0 15px 20px;}
  .order_list:nth-of-type(2n) {
    background-color: $defaultBg2;
  }
  .order_list {
    padding: 20px 0;
    border-bottom: 1px solid $defaultLgray;
    .order_list_inner {
      padding: 0 15px;
    }
    .order_status_wrap {
      @include flexBetween;
      padding-bottom: 15px;
      align-items: center;
      .order_status strong {
        display: inline-block;
        padding-right: 10px;
      }
    }
    .order_cate {
      padding-bottom: 15px;
      color: $defaultDgray;
    }
    .order_contents_wrap {
      @include flexBetween;
      flex-wrap: wrap;
      margin-bottom: 10px;
      .order_contents_img_wrap {
        img {
          width: 100%;
          max-width: 106px;
          height: 100%;
          max-height: 106px;
        }
      }
      .order_contents_info_wrap {
        position: relative;
        width: calc(100% - 126px);
        .order_contents_info_pax {
          position: absolute;
          top: -35px;
          color: $defaultDgray;
        }
        h4 {
          @include ellipsis(2);
          margin-bottom: 13px;
        }
        div {
          @include flexBetween;
          h3 {display: inline-block;}
        }
        div:first-of-type{padding-bottom: 10px;}
      }
    }
    .order_mission_status_wrap {
      @include flexBetween;
      align-items: center;
      div {
        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
      .mission_status_done {margin-right: 20px; color: $defaultBlue;}
      .mission_status_fail {color: $defaultDgray;}
    }
  }
}
.order_detail_status_wrap {
  padding: 0 15px;
  @include flexBetween;
  align-items: center;
}
.order_detail_lists {
  .order_list {border-bottom: 0;}
  .order_detail_subject {
    padding-bottom: 15px;
    font-size: $defaultSm;
    font-weight: bold;
    color: $defaultDgray;
  }
  .order_detail_history {
  @include flex;
  padding-bottom: 20px;
  line-height: 2;
  font-size: $defaultSm;
  flex-wrap: wrap;
  dt {width: 100px;}
  dd {width: calc(100% - 120px);}
  }
  .order_detail_key_wrap {
    .container_inner {padding: 20px 15px;}
    .key_wrap:first-of-type {border-top: 1px solid $defaultLgray;}
    .key_none {
      display: block;
      padding: 50px 0;
      text-align: center;
      border-top:1px solid $defaultLgray;
      border-bottom:1px solid $defaultLgray;
      background-color: $defaultBg2;
      color: $defaultDgray;
      }
    .key_wrap {
      padding: 20px 15px;
      border-bottom: 1px solid $defaultLgray;
      background-color: $defaultBg2;
      .key_copy_wrap {
        position: relative;
        input {
          margin-bottom: 10px;
          text-align: center;
        }
        button {
          position: absolute;
          @include yCenter;
          right: 22px;
          width: 100%;
          max-width: 61px;
          height: 30px;
          border-radius: 5px;
          background-color: $defaultBlue;
          font-weight: bold;
          color:#fff;
        }
      }
      .key_send_wrap {
        @include flexBetween;
        column-gap: 10px;
        button, a {
          display: inline-block;
          width: 100%;
          height: 30px;
          border-radius: 5px;
          font-weight: bold;
          color:#fff;
        }
        .btn_key_copy {background-color: $defaultRed;}
        .btn_key_play {background-color: $defaultBlue;}
        .btn_send_kakao {
          line-height: 30px;
          padding: 0 10px 0 15px;
          background: url(@assets/img/icon_kakao.svg) no-repeat 10px center/18px #FEE500;
          text-align: right;
          color:#000;
          @include mediaQuery(sm) {
            padding: 0 10px;
            text-align: center;
            background: #FEE500;
          }
        }
      }
    }
  }
}

/* 랭킹 */
.rank_search_wrap {
  @include flex;
  width: 100%;
  padding: 15px;
  align-items: center;
  column-gap: 10px;
  border-bottom: 1px solid $defaultLgray;
  input {
    width: calc(100% - 50px);
  }
}
.rank_mine_wrap {
  padding: 20px 15px;
  button {
    strong {color:#000;}
  }
  .rank_mine {
    @include flex;
    padding: 10px 15px;
    column-gap: 15px;
    align-items: center;
    border-radius: $defaultRadius;
    background-color: $defaultGreen;
    .rank_mine__img {
      width: 60px;
      height: 60px;
      border: 2px solid #ff9400;
      border-radius: 50%;
      img {background-color: #fff;}
    }
    .rank_mine__text {
      line-height: 2.2;
      color:#fff;
      h3, h4 {display: inline-block;}
      h4 {
        padding-right: 10px;
        font-weight: 500;
      }
    }
    &.active {display: none;}
  }
}
.rank_lists {
  .rank_list {
    @include flex;
    padding: 17px 3px;
    column-gap: 15px;
    border-bottom: 1px solid $defaultLgray;
    align-items: center;
    .rank_list__no {color:$defaultGreen;}
    .rank_list__img img {
      width: 40px;
      min-width:40px;
      height: 40px;
      border-radius: 50%;
      box-shadow: 0 2px 4px rgba(0,0,0,.3);
    }
    .rank_list__text {
      strong {
        display: block;
        padding-bottom: 5px;
      }
    }
  }
  .rank_list:nth-of-type(1),
  .rank_list:nth-of-type(2),
  .rank_list:nth-of-type(3) {
    padding: 20px 10px 16px;
    .rank_list__no {display: none;}
    .rank_list__img {
      position: relative;
      img {
        width: 60px;
        height: 60px;
      }
      &::before {
        content:"";
        position: absolute;
        top: -14px;
        left: -14px;
        display: inline-block;
        width: 36px;
        height: 36px;
      }
    }
  }
  .rank_list:nth-of-type(1) .rank_list__img {&::before {background: url(@assets/img/img_r1.png) no-repeat center/contain;}}
  .rank_list:nth-of-type(2) .rank_list__img {&::before {background: url(@assets/img/img_r2.png) no-repeat center/contain;}}
  .rank_list:nth-of-type(3) .rank_list__img {&::before {background: url(@assets/img/img_r3.png) no-repeat center/contain;}}
}
.rank_user_wrap {
  padding: 11px 0 20px;
}
.rank_mission_items {
  .mission_title {
    @include ellipsis(3)
  }
  .mission_info_result {
    display: inline-block;
    width: auto;
    padding: 3px 15px;
    text-align: center;
    border:1px solid $defaultGreen;
    border-radius: 5px;
    font-weight: bold;
    color: $defaultGreen;
  }
  .mission_info_result__fail {
    border: 1px solid $defaultRed;
    color:$defaultRed;
  }
}
.mission_team_notice {padding: 11px 0 15px;}
.mission_team_desc {
  display: inline-block;
  padding-bottom: 20px;
}

/* 마이페이지 */
.my_profile_wrap{
  @include flexBetween;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  .my_profile_img_wrap {
    position: relative;
    .my_profile__img {
      width: 83px;
      height: 83px;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba(0,0,0,.3);
    }
    .my_profile__attach {
      position: absolute;
      right: 5px;
      bottom: 0;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-image: linear-gradient(180deg, $defaultGreen, $defaultBlue);
      overflow: hidden;
      label {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: url(@assets/img/icon_media_wh.png) no-repeat center/21px;
      }
      input[type="file"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: absolute;
        left: -9999px;
        font-size: 0;
        visibility: hidden;
      }
  }
  }
  .my_profile_info_wrap {
    width: calc(100% - 100px);
    .undline {color: $defaultDgray;}
    .my_profile_info_links {
      @include flexBetween;
    }
  }
}
.my_profile_result_wrap {
  height: 100%;
  margin-bottom: 20px;
  border-radius: $defaultRadius;
  background-color: $defaultGreen;
  font-weight: bold;
  color:#fff;
  div {
    position: relative;
    width: 33.33333%;
    padding: 11px 0;
    text-align: center;
    &::after {
      content:"";
      position: absolute;
      @include yCenter;
      left: 100%;
      display: inline-block;
      width: 1px;
      height: 15px;
      background-color: #fff;
    }
    &:last-of-type::after {display: none;}
  }
}
.my_profile_lists {
  padding: 20px 15px;
  background-color: $defaultBg2;
  white-space: nowrap;
  .triple_wrap {
    div {
      width: calc(33.33333% - 12px);
      padding: 20px 24px;
      border:1px solid #E5E5E5;
      border-radius: 7px;
      text-align: center;
      background-color: #fff;
      img {
        display: block;
        max-width: 24px;
        margin: 0 auto 6px;
      }
    }
  }
}
.my_page_menus {
  .my_page_menu {
    padding: 20px 30px;
    border-bottom: 1px solid $defaultLgray;
    font-weight: bold;
    background: url(@assets/img/arrow_right_gray_thick.png) no-repeat calc(100% - 30px) center/8px;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
.mypage_edit_flex {
  @include flexBetween;
  a {
    font-size: $defaultSm;
    color: $defaultDgray;
  }
}
#edit_account {
  .input_warn {display: none;}
}
.edit_login_kakao {
  display: block;
  width: calc(100% - 30px);
  margin: 0 auto 25px;
  height: 50px;
  line-height: 50px;
  border-radius: $defaultRadius;
  background: url(@assets/img/icon_kakao.svg) no-repeat 15px center/18px #FEE500;
  font-weight: bold;
  text-align: center;
}

/* 나의 미션 */
.my_missoin_cate_wrap {
  @include flexBetween;
  color: $defaultDgray;
}
.my_mission_progress_wrap {
  margin-bottom: 15px;
  .mission_progress_fill {
    background-image: linear-gradient(90deg, $defaultGreen, $defaultBlue);
  }
}
.mission_contents_wrap {
  p {margin-bottom: 15px;}
  dl {
    padding: 10px;
    border: 1px solid $defaultLgray;
    border-radius: $defaultRadius;
  }
}
.my_mission_btn_wrap {
  @include flexBetween;
  .my_mission_links {
    @include flex;
    column-gap: 10px;
    align-items: center;
  }
}

/* 경품 */
.choose_rewards_wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;
  column-gap: 15px;
  word-break: keep-all;
  text-align: center;
  label {
    img {
      display: block;
      max-width: 95px;
      min-height: 95px;
      margin: 0 auto 10px;
      border: 1px solid $defaultLgray;
      border-radius: $defaultRadius;
    }
    strong {
      display: block;
      min-height: 50px;
      padding-bottom: 10px;
      color: $defaultDgray;
    }
  }
}
.rewards_info_flex {
  @include flexBetween;
  align-items: center;
  color: $defaultDgray;
  input {margin-right: 5px;}
  div {@include flex;}
}
.my_mission_reward {
  max-width: 33.3333%;
  margin-bottom: 20px;
  img {display: block;}
  strong {
    font-size: $defaultWarn;
    color:$defaultDgray;
  }
}
.my_rewards_info_wrap {
  .my_rewards_info {
    padding-bottom: 15px;
    margin-bottom: 14px;
    border-bottom: 1px solid $defaultLgray;
    strong {
      display: block;
      padding-bottom: 25px;
    }
  }
}

/* 관광지 */
.courses_wrap {
  width: 100%;
  .courses_title {
    padding: 0 15px;
    img {
      max-height: 23px;
      margin-right: 10px;
    }
    font-weight: bold;
    color: $defaultGreen;
  }
  .courses_item {
    width: 100%;
    padding: 15px 20px;
    border-bottom: 1px solid $defaultLgray;
    h3 {margin-bottom: 20px;}
    .img_wrap {
      display: block;
      width: 100%;
      height: 200px;
      margin-bottom: 15px;
      img {
        width: 100%;
        max-width: 477px;
        height: 100%;
        border:1px solid $defaultLgray;
        border-radius: $defaultRadius;
        overflow: hidden;
      }
    }
  }

}
/* 경품 */
.choose_rewards_wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;
  column-gap: 15px;
  word-break: keep-all;
  text-align: center;
  label {
    img {
      display: block;
      max-width: 95px;
      min-height: 95px;
      margin: 0 auto 10px;
      border: 1px solid $defaultLgray;
      border-radius: $defaultRadius;
    }
    strong {
      display: block;
      min-height: 50px;
      padding-bottom: 10px;
      color: $defaultDgray;
    }
  }
}
.rewards_info_flex {
  @include flexBetween;
  align-items: center;
  color: $defaultDgray;
  input {margin-right: 5px;}
  div {@include flex;}
}
.my_mission_reward {
  max-width: 33.3333%;
  margin-bottom: 20px;
  img {display: block;}
  strong {
    font-size: $defaultWarn;
    color:$defaultDgray;
  }
}
.my_rewards_info_wrap {
  .my_rewards_info {
    padding-bottom: 15px;
    margin-bottom: 14px;
    border-bottom: 1px solid $defaultLgray;
    strong {
      display: block;
      padding-bottom: 25px;
    }
  }
}

/* 약관 */
.terms_tab_wrap {
  width: 100%;
  max-width: 1000px;
  padding: 20px 15px 16px;
  border-bottom: 1px solid $defaultLgray;
  white-space: nowrap;
  overflow-x: auto;
  font-weight: bold;
  label {
    padding: 0 7.5px;
    color:#ccc;
    &.active,&:hover {color: $defaultGreen;}
  }
  &::-webkit-scrollbar {
    width: 100%;
    height: 1px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 20%;
    height: 1px;
    background-color: transparent;
  }
}
.term_container {
  input {display: none;}
  .term_content {display: none;}
  input:checked + .term_content {display: block;}
}

/* 공지사항 & 이벤트 */
.notice_wrap {
  ul {
    li {
      width: 100%;
      height: 100%;
      border-top: 1px solid $defaultLgray;
      &.active {
        background-color: $defaultBg2;
        .notice_pin {
          display: inline-block;
          padding: 3px 10px;
          margin-right: 10px;
          border:1px solid $defaultRed;
          border-radius: 5px;
          font-size: $defaultWarn;
          font-weight: bold;
          text-align: center;
          background-color: #fff;
          color:$defaultRed;
        }
      }
      a {
        @include flexBetween;
        width: 100%;
        height: 100%;
        padding: 20px 15px;
        align-items: center;
        .notice_title {
          @include ellipsis(1)
        }
      }
    }
    li:last-of-type {border-bottom: 1px solid $defaultLgray;}
  }
}
.notice_view_top {
  padding: 0 15px 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $defaultLgray;
  h2 {margin-bottom: 10px;}
}
.event_wrap {
  ul {
    li {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $defaultLgray;
      .event_item__img {
        width: 100%;
        max-height: 400px;
        margin-bottom: 15px;
        border-radius: 7px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .event_item__title {
        @include ellipsis(1);
        padding-bottom: 13px;
      }
      .event_item__date {
        @include flexBetween;
        font-weight: bold;
        h2 {color: $defaultGreen;}
      }
    }
  }
}
/* ======= 서브 끝 ======= */

/* ======= 푸터 ======= */
#footer {
  padding: 15px 15px 100px;
  background-color: $defaultBg2;
  span.bar {
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 10px;
    background-color: $defaultDgray;
    vertical-align: middle;
  }
  small {
    line-height: 1.6;
    color: $defaultDgray;
  }
}
/* ======= 푸터 끝 ======= */